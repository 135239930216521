import { useContext, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Button from '../../../components/Button';
import environmentConfig from '../../../config/environment-configs';
import { ParentalConsentContext } from '../index';
import { useAuth } from '../../../hooks/useAuth';
import Checkbox from '../../../components/Form/Checkbox';
import { Actions, Categories } from '../../../types/gtm';

const ConfirmDelete = styled.div`
  font-size: 12px;
  padding-top: 32px;
  line-height: 1.5;
  div {
    margin-top: 3px;
  }
`;

const ConfirmPasswordContainer = styled.div`
  width: 312px;
  margin-top: 40px;
  @media (max-width: 650px) {
    width: 95%;
    margin: 0;
  }

  h1 {
    font-family: 'GlobotipoRounded-Bd';
    margin: 0 0 16px 0;
    font-size: 20px;
    color: #000000;
  }

  label {
    width: 100%;
    display: flex;
    font-size: 14px;
  }

  p {
    margin: 0;
    color: #333333;
    font-size: 16px;
    line-height: 22px;
  }

  a {
    align-self: center;
    margin: 8px 0 0px 0;
    font-size: 12px;
    font-weight: bold;
  }

  button {
    margin-top: 32px;
  }
`;

const actionMap: Record<string, string> = {
  block: 'Bloquear',
  unblock: 'Desbloquear',
  delete: 'Remover',
};

const Confirm = () => {
  const { user } = useAuth();
  const { underageUser, step, dateToBlock } = useContext(
    ParentalConsentContext,
  );
  const [check, setCheck] = useState<boolean>(false);

  const index = step.type || 'block';
  const action = actionMap[index];

  function disabledButton() {
    return step.type === 'delete' && !check;
  }

  function getActionEventGA() {
    switch (step.type) {
      case 'unblock':
        return Actions.ClickConfirmUnblock;
      case 'delete':
        return Actions.ClickConfirmExclusion;
      default:
        return Actions.ClickConfirmBlock;
    }
  }

  function getTitle() {
    if (step.type === 'delete') {
      return `Para excluir a conta confirme sua senha`;
    }
    return `Para ${action.toLowerCase()} a conta confirme sua senha`;
  }

  const redirectToConfirmPassword = () => {
    const confirmPasswordID = `${Math.floor(Math.random() * 999999) + 1}`;

    const confirmData = {
      stepType: `${step.type}`,
      dateToBlock: dateToBlock ? `${moment(dateToBlock).format()}` : '',
    };

    sessionStorage.setItem(confirmPasswordID, JSON.stringify(confirmData));
    localStorage.setItem(
      'redirect',
      `consentimento-parental/${underageUser.underageId}/confirma-senha/${confirmPasswordID}/submitConfirm`,
    );

    window.location.href = environmentConfig.REDIRECT_URL;
  };

  return (
    <>
      <ConfirmPasswordContainer>
        <h1>{getTitle()}</h1>
        <p>
          Fique atento! Essa ação vai {action.toLowerCase()} a Conta Globo de{' '}
          {underageUser.name}, que perderá acesso a sua conta e aos produtos
          Globo associados a ela de forma definitiva.
        </p>
        {step.type === 'delete' && (
          <ConfirmDelete>
            <label htmlFor="checkbox">
              <Checkbox
                id="checkbox"
                checked={check}
                onChange={e => setCheck(e.currentTarget.checked)}
                name="checkbox"
              />{' '}
              Estou ciente que, ao excluir a Conta Globo de {underageUser.name},
              tal conta será apagada permanentemente.
            </label>
          </ConfirmDelete>
        )}
        <Button
          width="100%"
          type="submit"
          disabled={disabledButton()}
          variant="secondary"
          onClick={() => {
            redirectToConfirmPassword();
            window.dataLayer.push({
              event: 'customEventUserInteraction',
              event_category: Categories.ParentalConsent,
              event_action: getActionEventGA(),
              event_label: underageUser.underageId,
              bd_suser_code: user?.globoId,
            });
          }}
        >
          Confirmar senha
        </Button>
      </ConfirmPasswordContainer>
    </>
  );
};

export default Confirm;
