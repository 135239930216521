import { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as More } from '../../../../static/img/more.svg';
import { ReactComponent as Clock } from '../../../../static/img/clock.svg';
import { ReactComponent as Alert } from '../../../../static/img/alert-invite-expired.svg';
import Icon from '../../../../components/Section/Icon';
import Cancel from '../../../../static/img/cancel.svg';
import Send from '../../../../static/img/send.svg';

import {
  ItemContainer,
  Email,
  Container,
  LetterAvatar,
  CustomButton,
} from '../StylesComponents';
import { ModalState } from '../OwnerPage/OwnerPage';
import { Actions, Categories } from '../../../../types/gtm';
import { User } from '../../../../types/user';

const InfoDiv = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: #333333;
  margin-top: 4px;
  @media (max-width: 650px) {
    font-size: 14px;
  }
  > svg {
    margin-right: 8px;
  }
  > p {
    margin: 0 0 2px 0;
  }

  .redInfo {
    color: #d10000;
    font-weight: 600;
  }
`;

const DivMoreButton = styled.div`
  position: relative;
`;

const DropdownMoreButton = styled.div`
  position: absolute;
  list-style-type: none;
  margin: 0;
  right: 0px;
  width: 206px;
  height: 105px;
  background-color: #ffffff;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 31%);
  z-index: 3;
  border-radius: 4px;
  > ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
    > li:first-child {
      border-bottom: 1px solid #eeeeee;
    }
    > li {
      height: 53px;
      padding: 0;
      > button {
        padding: 16px 24px;
        height: 100%;
        border: none;
        justify-content: center;
        display: flex;
        align-items: center;
        background: transparent;
        font-family: ‘Open-Sans’, sans-serif;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        letter-spacing: -0.34px;
        width: 100%;
        color: ${props => props.theme.colors?.primary.main.color};
        padding: 0;
        cursor: pointer;
        img {
          margin-right: 10px;
        }
      }
    }
  }
`;

const getFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase();
};

type InvitedListItemProps = {
  user: User;
  email: string;
  expirationDate: string;
  inviteId: string;
  setResendModal: (modal: ModalState) => void;
  setCancelModal: (modal: ModalState) => void;
};

const InvitedListItem = ({
  user,
  email,
  expirationDate,
  inviteId,
  setResendModal,
  setCancelModal,
}: InvitedListItemProps) => {
  const [dropdown, setDropdown] = useState<boolean>(false);
  window.dataLayer = window.dataLayer || [];

  const resend = async () => {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.FamilyOwner,
      event_action: Actions.ClickResendInvite,
      event_label: 'Tela Inicial',
      bd_suser_code: user?.globoId,
    });

    setDropdown(false);
    setResendModal({
      show: true,
      email,
      inviteId,
    });
  };

  const cancel = async () => {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.FamilyOwner,
      event_action: Actions.ClickCancelInvite,
      event_label: 'Tela Inicial',
    });

    setDropdown(false);
    setCancelModal({
      show: true,
      email,
      inviteId,
    });
  };

  function formateDate(date: string) {
    const datePTBR = new Date(date);
    return datePTBR.toLocaleDateString('pt-BR');
  }

  function isExpired(dateStr: string) {
    const expiredDate = new Date(dateStr);
    const dateNow = new Date();
    return dateNow.getTime() > expiredDate.getTime();
  }

  let textInvite = 'convite expira em ';
  if (isExpired(expirationDate)) {
    textInvite = 'convite expirado em ';
  }
  textInvite += formateDate(expirationDate);

  return (
    <Container>
      <LetterAvatar aria-hidden pendingInvite>
        {getFirstLetter(email)}
      </LetterAvatar>
      <ItemContainer>
        <Email bold>{email}</Email>
        <InfoDiv>
          {isExpired(expirationDate) ? (
            <>
              <Alert aria-hidden />
              <p className="redInfo">{textInvite}</p>
            </>
          ) : (
            <>
              <Clock aria-hidden fill="#333333" />
              <p>{textInvite}</p>
            </>
          )}
        </InfoDiv>
      </ItemContainer>
      <DivMoreButton>
        <CustomButton
          aria-label="Ver mais"
          onClick={() => setDropdown(!dropdown)}
        >
          <More fill="#005cde" />
        </CustomButton>
        {dropdown && (
          <DropdownMoreButton>
            <ul>
              <li>
                <button type="button" onClick={resend}>
                  <Icon svg={Send} alt="reenviar" />
                  <span>Reenviar convite</span>
                </button>
              </li>
              <li>
                <button type="button" onClick={cancel}>
                  <Icon svg={Cancel} alt="cancelar" />
                  <span>Cancelar convite</span>
                </button>
              </li>
            </ul>
          </DropdownMoreButton>
        )}
      </DivMoreButton>
    </Container>
  );
};

export default InvitedListItem;
