import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  HeaderContainer,
  UserMenu,
  OpenUserMenu,
  ReturnButton,
  Logo,
  Button,
} from './styles';
import { User } from '../../types/user';
import Icon from '../Section/Icon';
import iconOpenCollapse from '../../static/img/icon-open-collapse.svg';
import iconReturn from '../../static/img/icon-open-menu.svg';
import svg from '../../static/img/logo-globo.svg';
import { Categories, Actions, Labels } from '../../types/gtm';

export interface HeaderUserProps {
  user?: User;
  screenMenu?: string;
  onLogout?: () => void;
  showReturn?: boolean;
  labelMenu?: string;
  onReturn?: () => void;
}

export default function Header({
  user,
  onLogout,
  showReturn,
  onReturn,
  labelMenu,
  screenMenu = Categories.Home,
}: HeaderUserProps) {
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  window.dataLayer = window.dataLayer || [];

  return (
    <HeaderContainer>
      {showReturn && (
        <div id="divButton">
          <ReturnButton
            onClick={() => {
              if (!onReturn) {
                history.goBack();
                return;
              }

              onReturn();
            }}
          >
            <>
              <Icon svg={iconReturn} alt="icon-return" /> <span>Voltar</span>
            </>
          </ReturnButton>
        </div>
      )}

      <Logo>
        <img src={svg} alt="Conta Globo" />
      </Logo>
      {user && (
        <UserMenu
          headerWithReturnButton={showReturn || false}
          onClick={() => {
            if (!open) {
              window.dataLayer.push({
                event: 'customEventUserInteraction',
                event_category: screenMenu,
                event_action: Actions.ClickMenu,
                event_label:
                  screenMenu === Categories.Home ? Labels.Home : labelMenu,
                bd_suser_code: user?.globoId,
              });
            }

            setOpen(!open);
          }}
        >
          <Button aria-expanded={open} aria-haspopup="true">
            <span>{user.email ? user.email : user.fullName}</span>
            <Icon openCollapsible={open} svg={iconOpenCollapse} />
          </Button>

          {open ? (
            <OpenUserMenu>
              <span className="top-bot" />
              <span className="top-bot top-bot-border" />
              <span>{user.fullName}</span>
              <br />
              <a
                href="#/logout"
                className="logout"
                onClick={() => {
                  if (onLogout) {
                    onLogout();
                  }
                  window.dataLayer.push({
                    event: 'customEventUserInteraction',
                    event_category: screenMenu,
                    event_action: Actions.ClickLogoutMenu,
                    event_label:
                      screenMenu === Categories.Home ? Labels.Home : labelMenu,
                    bd_suser_code: user?.globoId,
                  });
                }}
              >
                Sair da Conta Globo
              </a>
            </OpenUserMenu>
          ) : null}
        </UserMenu>
      )}
    </HeaderContainer>
  );
}
