import React, { useEffect, useState, createContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ToastContainer } from 'react-toastify';
import { api } from '../../services/api';
import { useAuth } from '../../hooks/useAuth';
import { Loading } from '../../components/Loading';
import { Constraints, Subscription } from '../../types/user';
import DeleteReason from './DeleteReason';
import DeleteConfirm from './DeleteConfirm';
import DeleteSuccess from './DeleteSuccess';
import DeleteSubscriptions from './DeleteSubscriptions';
import Container from '../../components/Container';
import Header from '../../components/Header';
import { Categories } from '../../types/gtm';

export type PropsDeleteAccountContext = {
  userDeleteReasons: string[];
  reasons: string[];
  setUserDeleteReasons: React.Dispatch<React.SetStateAction<string[]>>;
  changeUserDeleteReasons: (key: string, value: string | unknown) => void;
  quarantineDays: number;
  step: string;
  setStep: React.Dispatch<React.SetStateAction<string>>;
  subscriptionData: Subscription;
};

const defaultValue = {
  userDeleteReasons: [],
  reasons: [],
  setUserDeleteReasons: () => ({}),
  changeUserDeleteReasons: () => undefined,
  quarantineDays: 0,
  step: '',
  setStep: () => ({}),
  subscriptionData: {
    signatures: [],
    isUserDeletionAllowedByAllSubscriptions: false,
  },
};

export const DeleteAccountContext =
  createContext<PropsDeleteAccountContext>(defaultValue);

const DeleteAccount = () => {
  const history = useHistory();
  const { step: routeStep } = useParams<{ step: string }>();
  const [step, setStep] = useState<string>(defaultValue.step);
  const { user, logout, authenticateOnAPI } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [reasons, setReasons] = useState<string[]>(defaultValue.reasons);
  const [quarantineDays, setQuarantineDays] = useState<number>(
    defaultValue.quarantineDays,
  );
  const [userDeleteReasons, setUserDeleteReasons] = useState<string[]>(
    defaultValue.userDeleteReasons,
  );
  const [subscriptionData, setSubscriptionData] = useState<Subscription>(
    defaultValue.subscriptionData,
  );

  const changeUserDeleteReasons = (value: string) => {
    setUserDeleteReasons([...userDeleteReasons, value]);
  };

  const getReasons = async () => {
    const token = await authenticateOnAPI();
    api
      .get('/account/exclusion/options', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<[]>) => {
        setReasons(response.data);
      })
      .catch(() => {
        setReasons([]);
      });
  };

  const getQuarantineDays = async () => {
    const token = await authenticateOnAPI();
    api
      .get('/account/constraints', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<Constraints>) => {
        setQuarantineDays(response.data.QuarantineDays);
      })
      .catch(() => {
        setQuarantineDays(defaultValue.quarantineDays);
      });
  };

  useEffect(() => {
    const getCanDelete = async () => {
      setLoading(true);
      const token = await authenticateOnAPI();
      api
        .get('/account/can-be-removed?checkSubscriptions=true', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response: AxiosResponse<Subscription>) => {
          setSubscriptionData(prevState => {
            return { ...prevState, ...response.data };
          });
          setStep(routeStep || 'subscriptions');
        })
        .catch(() => {
          setStep('error');
        })
        .finally(() => setLoading(false));
    };

    getReasons();
    getQuarantineDays();
    getCanDelete();
  }, []);

  const renderComponentByStep = () => {
    if (loading) {
      return <Loading />;
    }
    switch (step) {
      case 'reason':
        return <DeleteReason />;
      case 'confirm':
        return <DeleteConfirm />;
      case 'success':
        return <DeleteSuccess user={user} />;
      case 'subscriptions':
        return <DeleteSubscriptions />;
      default:
        return <Loading />;
    }
  };

  return (
    <DeleteAccountContext.Provider
      value={{
        userDeleteReasons,
        reasons,
        setUserDeleteReasons,
        changeUserDeleteReasons,
        quarantineDays,
        step,
        setStep,
        subscriptionData,
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="colored"
      />
      <Header
        user={user}
        onLogout={logout}
        showReturn
        screenMenu={Categories.DeleteAccount}
        onReturn={() => {
          if (!['', 'reason', 'subscriptions', 'error'].includes(step)) {
            setStep('reason');
          } else {
            history.push('/');
          }
        }}
      />
      <Container errorInRequest={step === 'error'}>
        {renderComponentByStep()}
      </Container>
    </DeleteAccountContext.Provider>
  );
};

export default DeleteAccount;
