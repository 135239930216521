import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import Countdown from 'react-countdown';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import OtpInput from '../../../../components/OtpInput';
import { ModalProps } from '../../PhoneForm/PhoneForm';
import { LoadingSpinner } from '../../../../components/Loading/styles';
import environmentConfig from '../../../../config/environment-configs';
import { api } from '../../../../services/api';
import confirmIcon from '../../../../static/img/account-management.svg';
import Status from '../../../../components/Status';
import Message from '../../../../components/Message';
import { Actions, GetInterventionCategory } from '../../../../types/gtm';
import { useAuth } from '../../../../hooks/useAuth';

import {
  ModalButtons,
  TextSubHeaderModal,
  ModalFooter,
  ModalBody,
  ModalParaghaph,
  TextHeaderModal,
  ModalHeader,
} from './styles';

export type ValidationMessage = {
  text: string;
  type: 'error' | 'warn' | 'info' | 'success';
  show: boolean;
};

export type CountdownProps = {
  seconds: number;
  completed: boolean;
};

const ConfirmMobileModal = ({
  modal,
  setModal,
  close,
  onConfirm,
  onConfirmResult,
}: ModalProps) => {
  const { user, hideMobilePhoneIntervention } = useAuth();

  const getRecaptchaToken = async () => {
    return new Promise(resolve => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(environmentConfig.GOOGLE_RECAPTCHA_KEY, {
            action: 'envioDeTokenSMS',
          })
          .then((token: string) => {
            resolve(token);
          });
      });
    });
  };

  const [otpMessage, setOtpMessage] = useState<ValidationMessage>({
    text: '',
    type: 'info',
    show: false,
  });

  const [resendMessage, setResendMessage] = useState<ValidationMessage>({
    text: '',
    type: 'info',
    show: false,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const [loadingResend, setResendLoading] = useState<boolean>(false);

  const [resend, setResend] = useState<boolean>(true);

  const [success, setSuccess] = useState<boolean>(false);

  const maxConfirmRetries = 10;

  const [otp, setOtp] = useState<string>('');

  useEffect(() => {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: GetInterventionCategory(user),
      event_action: Actions.Pageview,
      event_label: 'Tela de Confirmacao de Codigo',
      bd_suser_code: user?.globoId,
    });
  }, []);

  const renderer = ({ seconds, completed }: CountdownProps) => {
    if (completed) {
      return null;
    }
    return (
      <Message type="warn">
        Por favor, aguarde {seconds} segundos antes de requisitar um novo código
      </Message>
    );
  };

  const setResendCountDown = (enabled: boolean) => {
    setResend(!enabled);
    setResendMessage({
      show: false,
      text: '',
      type: 'info',
    });
  };

  const resendToken = async () => {
    if (loading) {
      return;
    }

    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: GetInterventionCategory(user),
      event_action: Actions.ClickResendToken,
      event_label: 'Tela de Confirmacao de Codigo',
      bd_suser_code: user?.globoId,
    });

    setResendMessage({
      show: false,
      text: '',
      type: 'info',
    });

    setResendLoading(true);
    const token = sessionStorage.getItem('token_jwt');

    const body = {
      dddCellphone: modal.dddCellPhone,
      cellPhone: modal.cellPhone,
    };

    const recaptchaToken = await getRecaptchaToken();
    api
      .put('/user/mobile/request', body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Recaptcha-Token': recaptchaToken,
        },
      })
      .then(() => {
        setResendLoading(false);
        window.dataLayer.push({
          event: 'customEventUserInteraction',
          event_category: GetInterventionCategory(user),
          event_action: Actions.Success,
          event_label: 'Codigo Reenviado',
          bd_suser_code: user?.globoId,
        });
        setResendMessage({
          show: true,
          type: 'success',
          text: 'Novo código enviado com sucesso',
        });
        setModal({ ...modal, alreadyRequest: true });
      })
      .catch((error: AxiosError) => {
        window.dataLayer.push({
          event: 'customEventUserInteraction',
          event_category: GetInterventionCategory(user),
          event_action: Actions.Error,
          event_label: 'Erro ao Reenviar Codigo',
          bd_suser_code: user?.globoId,
        });
        switch (error.response?.status) {
          case 429:
            setResendCountDown(true);
            break;
          default:
            setResendMessage({
              show: true,
              type: 'error',
              text: 'Não foi possível enviar um novo código. Tente novamente mais tarde',
            });
        }
        setResendLoading(false);
        setModal({ ...modal, alreadyRequest: true, requestMobilePhone: false });
      });
  };

  async function confirm() {
    if (loading) {
      return;
    }
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: GetInterventionCategory(user),
      event_action: Actions.ClickConfirmCellPhone,
      event_label: 'Tela de Confirmacao de Codigo',
      bd_suser_code: user?.globoId,
    });
    setOtpMessage({
      show: false,
      text: '',
      type: 'info',
    });
    setLoading(true);

    const token = sessionStorage.getItem('token_jwt');

    const body = {
      dddCellphone: modal.dddCellPhone,
      cellPhone: modal.cellPhone,
      token: otp,
    };

    const recaptchaToken = await getRecaptchaToken();
    api
      .post('/user/mobile/confirm', body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Recaptcha-Token': recaptchaToken,
        },
      })
      .then(() => {
        setLoading(false);
        setSuccess(true);
        window.dataLayer.push({
          event: 'customEventUserInteraction',
          event_category: GetInterventionCategory(user),
          event_action: Actions.Success,
          event_label: 'Celular Validado',
          bd_suser_code: user?.globoId,
        });
        hideMobilePhoneIntervention(true);
        if (onConfirmResult) {
          onConfirmResult();
        }
        setModal({ ...modal, alreadyRequest: true });
      })
      .catch((error: AxiosError) => {
        const maxRetries = maxConfirmRetries - error.response?.data.retries;
        switch (error.response?.status) {
          case 409:
            window.dataLayer.push({
              event: 'customEventUserInteraction',
              event_category: GetInterventionCategory(user),
              event_action: Actions.Error,
              event_label: 'Celular já Existe',
              bd_suser_code: user?.globoId,
            });
            setOtpMessage({
              show: true,
              type: 'error',
              text: 'Este celular já está sendo usado. Por favor, informe outro celular.',
            });
            break;
          case 429:
            window.dataLayer.push({
              event: 'customEventUserInteraction',
              event_category: GetInterventionCategory(user),
              event_action: Actions.Error,
              event_label: 'Limite de Tentativas Excedido',
              bd_suser_code: user?.globoId,
            });
            setOtpMessage({
              show: true,
              type: 'error',
              text: 'Limite de tentativas excedido. Tente novamente mais tarde.',
            });
            break;
          case 417:
            window.dataLayer.push({
              event: 'customEventUserInteraction',
              event_category: GetInterventionCategory(user),
              event_action: Actions.Error,
              event_label: 'Codigo Invalido',
              bd_suser_code: user?.globoId,
            });
            setOtpMessage({
              show: true,
              type: 'error',
              text: `Informe o código recebido e tente novamente. Você tem mais ${maxRetries} tentativa${
                maxRetries > 1 ? 's' : ''
              }`,
            });
            break;
          default:
            setOtpMessage({
              show: true,
              type: 'error',
              text: 'Não foi possível confirmar o número de celular. Tente novamente mais tarde.',
            });
        }
        setLoading(false);
      });
  }

  function closeModal() {
    setSuccess(false);
    setModal({ ...modal, alreadyRequest: true });
    close();
  }

  if (success) {
    return (
      <Modal maxWidth="360px" contentHeight="320px">
        <ModalBody>
          <Status
            icon={`${confirmIcon}#icon-check-confirm`}
            title=" Seu celular foi cadastrado com sucesso, obrigado!"
            colorTitle="#000000"
          />
        </ModalBody>
        <Button
          variant="secondary"
          width="100%"
          onClick={() => {
            window.dataLayer.push({
              event: 'customEventUserInteraction',
              event_category: GetInterventionCategory(user),
              event_action: Actions.ClickBack,
              event_label: 'Tela de Confirmacao de Codigo',
              bd_suser_code: user?.globoId,
            });
            if (onConfirm) {
              onConfirm();
            } else {
              closeModal();
            }
          }}
        >
          Voltar
        </Button>
      </Modal>
    );
  }
  return (
    <Modal closeButton={() => closeModal()} maxWidth="360px">
      <ModalBody>
        <ModalHeader>
          <TextHeaderModal>Informe o código que você recebeu</TextHeaderModal>
        </ModalHeader>
        <ModalParaghaph>
          Para confirmar seu celular, enviamos um código por SMS para{' '}
          <b>{modal.number}</b>. Esse envio pode levar alguns minutos.
        </ModalParaghaph>
        <OtpInput
          autoFocus
          length={6}
          className="otpContainer"
          inputClassName="otpInput"
          onChangeOTP={onetime => setOtp(onetime)}
          errorMessage={otpMessage.text}
        />

        <ModalButtons>
          <Button
            variant="secondary"
            width="100%"
            onClick={() => confirm()}
            disabled={otp.length !== 6}
          >
            {loading ? <LoadingSpinner isButton /> : 'Confirmar código'}
          </Button>
          <Button
            variant="primary"
            width="100%"
            onClick={() => {
              window.dataLayer.push({
                event: 'customEventUserInteraction',
                event_category: GetInterventionCategory(user),
                event_action: Actions.ClickChangeNumber,
                event_label: 'Tela de Confirmacao de Codigo',
                bd_suser_code: user?.globoId,
              });
              closeModal();
            }}
          >
            Alterar número cadastrado
          </Button>
        </ModalButtons>

        <ModalFooter>
          <TextSubHeaderModal>Ainda não recebeu o código?</TextSubHeaderModal>
          <Button
            disabled={!resend}
            variant="secondary"
            width="100%"
            onClick={() => resendToken()}
          >
            {loadingResend ? <LoadingSpinner isButton /> : 'Reenviar Código'}
          </Button>
          {resend && resendMessage.show && (
            <Message type={resendMessage.type}>{resendMessage.text}</Message>
          )}
          {!resend && (
            <Countdown
              date={Date.now() + 30000}
              renderer={renderer}
              onStart={() => setResendCountDown(true)}
              onComplete={() => setResendCountDown(false)}
            />
          )}
          <Button
            variant="primary"
            width="100%"
            onClick={() => {
              window.dataLayer.push({
                event: 'customEventUserInteraction',
                event_category: GetInterventionCategory(user),
                event_action: Actions.ClickSkip,
                event_label: 'Tela de Confirmacao de Codigo',
                bd_suser_code: user?.globoId,
              });
              if (onConfirm) {
                onConfirm();
              } else {
                closeModal();
              }
            }}
          >
            Prefiro deixar para depois
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmMobileModal;
