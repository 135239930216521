import { toast } from 'react-toastify';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styles';
import Button from '../../../../components/Button';
import { LoadingSpinner } from '../../../../components/Loading/styles';
import Modal from '../../../../components/Modal';
import { ModalProps } from '../OwnerPage/OwnerPage';
import { familyApi } from '../../../../services/family-api';
import { Actions, Categories } from '../../../../types/gtm';

const ResendModal = ({
  user,
  modal,
  loading,
  setModal,
  setLoading,
  close,
}: ModalProps) => {
  const label = 'Tela Reenviar Convite';
  const labelPost = 'Tela Convite Reenviado';

  async function resend() {
    setLoading(true);

    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.FamilyOwner,
      event_action: Actions.ClickResendInvite,
      event_label: label,
      bd_suser_code: user?.globoId,
    });

    await familyApi
      .post('/v1/fe/invite/resend', {
        email: modal.email,
        iid: modal.inviteId,
      })
      .then(response => {
        window.dataLayer.push({
          event: 'customEventUserInteraction',
          event_category: Categories.FamilyOwner,
          event_action: Actions.Success,
          event_label: labelPost,
          bd_suser_code: user?.globoId,
        });
        setLoading(false);
        setModal({ ...modal, alreadyRequest: true });

        if (response.status !== 200) {
          toast.error('Ocorreu um erro ao reenviar o convite');
          close(false);
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error('Ocorreu um erro ao reenviar o convite');
        close(false);
      });
  }
  return (
    <Modal header="Reenviar convite?">
      <ModalBody>
        {!modal.alreadyRequest ? (
          <p>
            Deseja cancelar o envio de convite para
            <b> {modal.email}</b>?
          </p>
        ) : (
          <>
            <p>
              O convite foi reenviado com sucesso para
              <b> {modal.email}</b>.
            </p>
            <p>E agora?</p>
            <p>
              1. Seu familiar receberá um link com o convite pelo e-mail
              indicado.
            </p>
            <p>
              2. Será necessário fazer login ou criar uma Conta Globo através do
              link para aceitar o convite.
            </p>
            <p>
              3. Cada familiar acessa os benefícios compartilhados da família
              com sua própria Conta Globo, de forma segura e personalizada.
            </p>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          variant="primary"
          onClick={() =>
            close(
              modal.alreadyRequest,
              modal.alreadyRequest ? labelPost : label,
            )
          }
        >
          Fechar
        </Button>
        {!modal.alreadyRequest && (
          <Button variant="secondary" width="100%" onClick={() => resend()}>
            {loading ? <LoadingSpinner isButton /> : 'Reenviar'}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ResendModal;
