import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import GrantedListItem from '../GrantedListItem';
import { Granted, Invite, Product } from '../../../../types/family';
import Button from '../../../../components/Button';
import InvitedListItem from '../InvitedListItem';
import ProductListItem from '../ProductListItem';
import Icon from '../../../../components/Section/Icon';
import Add from '../../../../static/img/add.svg';
import {
  Link,
  OwnerPageContainer,
  SubTitle,
  SubTitleProducts,
} from '../StylesComponents';
import InviteModal from '../modals/InviteModal';
import ResendModal from '../modals/ResendModal';
import CancelModal from '../modals/CancelModal';
import RemoveModal from '../modals/RemoveModal';
import Title from '../../../../components/Title';
import Description from '../../../../components/Description';
import { Actions, Categories } from '../../../../types/gtm';
import { User } from '../../../../types/user';

const DivAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  @media (max-width: 650px) {
    flex-direction: column;
  }

  > div {
    @media (max-width: 650px) {
      width: 100%;

      &:not(:only-child) {
        margin-bottom: 24px;
      }
    }

    p {
      margin: 0;
    }
    > button {
      width: 100%;
      padding: 10px 24px 10px 24px;
      display: flex;
      justify-content: center;
      > img {
        margin-right: 8px;
      }
    }
  }
`;

export type ModalState = {
  show: boolean;
  globoId?: string;
  email?: string;
  name?: string;
  inviteId?: string;
  alreadyRequest?: boolean;
  error?: string;
};

export type ModalProps = {
  user: User;
  modal: ModalState;
  loading: boolean;
  setModal: (m: ModalState) => void;
  setLoading: (l: boolean) => void;
  close: (b?: boolean, s?: string) => void;
};

type OwnerPageProps = {
  user: User;
  userName: string;
  maxGranted: number;
  currentInFamily: number;
  grantedList: Array<Granted>;
  invitedList: Array<Invite>;
  productList: Array<Product>;
  getOwnerInfo: () => void;
};

const OwnerPage = ({
  user,
  userName,
  maxGranted,
  currentInFamily,
  productList,
  grantedList,
  invitedList,
  getOwnerInfo,
}: OwnerPageProps) => {
  const [inviteModal, setInviteModal] = useState<ModalState>({
    show: false,
  });
  const [resendModal, setResendModal] = useState<ModalState>({
    show: false,
  });
  const [cancelModal, setCancelModal] = useState<ModalState>({
    show: false,
  });
  const [removeModal, setRemoveModal] = useState<ModalState>({
    show: false,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const closeModal = (request?: boolean, type?: string) => {
    if (type) {
      window.dataLayer.push({
        event: 'customEventUserInteraction',
        event_category: Categories.FamilyOwner,
        event_action: Actions.ClickClose,
        event_label: type,
        bd_suser_code: user?.globoId,
      });
    }

    setCancelModal({ show: false });
    setResendModal({ show: false });
    setInviteModal({ show: false });
    setRemoveModal({ show: false });

    if (request) getOwnerInfo();
  };
  const addDependent = () => {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.FamilyOwner,
      event_action: Actions.ClickAddDependent,
      event_label: 'Tela Inicial',
      bd_suser_code: user?.globoId,
    });

    setInviteModal({ show: true, email: '' });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />

      <OwnerPageContainer>
        <Title>Gerencie sua família</Title>
        <Description>
          <p>
            Você pode compartilhar seus benefícios dos produtos Globo com até{' '}
            <b>{maxGranted}</b> familiares que moram na mesma casa.{' '}
            <Link
              onClick={() => {
                window.dataLayer.push({
                  event: 'customEventUserInteraction',
                  event_category: `${Categories.FamilyOwner}`,
                  event_action: Actions.ClickMore,
                  event_label: 'Tela Inicial',
                  bd_suser_code: user?.globoId,
                });
              }}
              href="http://globo.com/ajudacontafamilia"
              target="_blank"
            >
              Saiba mais.
            </Link>
          </p>

          {currentInFamily === 0 ? (
            <SubTitle>
              {userName.split(' ')[0]}, você ainda não tem dependentes.
            </SubTitle>
          ) : (
            <SubTitle>Seus dependentes</SubTitle>
          )}
          <>
            {grantedList.map(granted => (
              <GrantedListItem
                user={user}
                key={granted.globoId}
                {...granted}
                setModal={setRemoveModal}
              />
            ))}
            {removeModal.show && (
              <RemoveModal
                user={user}
                modal={removeModal}
                loading={loading}
                setModal={setRemoveModal}
                setLoading={setLoading}
                close={closeModal}
              />
            )}
            {invitedList.map(invited => (
              <InvitedListItem
                user={user}
                key={invited.inviteID}
                email={invited.email}
                expirationDate={invited.expirationDate}
                inviteId={invited.inviteID}
                setResendModal={setResendModal}
                setCancelModal={setCancelModal}
              />
            ))}
            {resendModal.show && (
              <ResendModal
                user={user}
                modal={resendModal}
                loading={loading}
                setModal={setResendModal}
                setLoading={setLoading}
                close={closeModal}
              />
            )}
            {cancelModal.show && (
              <CancelModal
                user={user}
                modal={cancelModal}
                loading={loading}
                setModal={setCancelModal}
                setLoading={setLoading}
                close={closeModal}
              />
            )}

            {currentInFamily < maxGranted ||
            maxGranted - currentInFamily > 0 ? (
              <DivAdd>
                {currentInFamily > 0 && (
                  <div>
                    <p>
                      Você ainda pode adicionar{' '}
                      <b>
                        {maxGranted - currentInFamily}{' '}
                        {maxGranted - currentInFamily === 1
                          ? 'dependente'
                          : 'dependentes'}
                      </b>
                      .
                    </p>
                  </div>
                )}
                <div>
                  <Button variant="secondary" onClick={addDependent}>
                    <Icon svg={Add} alt="+" /> Adicionar dependente
                  </Button>
                </div>
              </DivAdd>
            ) : (
              <DivAdd>
                <div>
                  <p>Sua família já está completa!</p>
                </div>
              </DivAdd>
            )}
          </>
        </Description>
        {inviteModal.show && (
          <InviteModal
            user={user}
            modal={inviteModal}
            loading={loading}
            setModal={setInviteModal}
            setLoading={setLoading}
            close={closeModal}
          />
        )}

        {productList.length > 0 && (
          <SubTitleProducts>
            Veja o que você está compartilhando com os seus dependentes
          </SubTitleProducts>
        )}
        {productList.map(product => (
          <ProductListItem
            key={product.id}
            description={product.description}
            logo={product.logo}
            name={product.name}
          />
        ))}
      </OwnerPageContainer>
    </>
  );
};
export default OwnerPage;
