import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { Avatar, LetterAvatar, Link } from '../StylesComponents';
import {
  GrantedPageContainer,
  Name,
  SubTitle,
  SubContent,
  Img,
  ProductContent,
} from './style';
import { familyApi } from '../../../../services/family-api';
import { Product } from '../../../../types/family';
import LeaveModal from '../modals/LeaveModal';
import Title from '../../../../components/Title';
import Description from '../../../../components/Description';
import Button from '../../../../components/Button';
import { User } from '../../../../types/user';
import { Actions, Categories } from '../../../../types/gtm';

type TitleAccountItemProps = {
  user: User;
  productList: Array<Product>;
  ownerId?: string;
  ownerName: string;
  picture: string;
  grantedId: string;
};

const getFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase();
};

export type ModalState = {
  show: boolean;
  globoId?: string;
  email?: string;
  name?: string;
  alreadyRequest?: boolean;
  error?: string;
  code?: number;
};

export type ModalProps = {
  user: User;
  modal: ModalState;
  loading: boolean;
  setModal: (m: ModalState) => void;
  setLoading: (l: boolean) => void;
  close: (b?: boolean, s?: string) => void;
};

const GrantedPage = ({
  user,
  picture,
  ownerId,
  ownerName,
  productList,
  grantedId,
}: TitleAccountItemProps) => {
  const [leaveModal, setLeaveModal] = useState<ModalState>({
    show: false,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const closeModal = (request?: boolean, type?: string) => {
    if (type) {
      window.dataLayer.push({
        event: 'customEventUserInteraction',
        event_category: Categories.FamilyGranted,
        event_action: Actions.ClickClose,
        event_label: type,
        bd_suser_code: user?.globoId,
      });
    }

    setLeaveModal({ show: false });
  };

  const leave = async () => {
    const params = {
      GrantedID: grantedId,
    };
    familyApi
      .get('/v1/fe/user/granted/check-leave', { params })
      .then((response: AxiosResponse<ModalState>) => {
        window.dataLayer.push({
          event: 'customEventUserInteraction',
          event_category: Categories.FamilyGranted,
          event_action: Actions.ClickLeaveFamily,
          event_label: 'Tela Inicial',
          bd_suser_code: user?.globoId,
        });
        setLoading(false);
        setLeaveModal({
          show: true,
          globoId: ownerId,
          name: ownerName,
          email: response.data.email,
          code: response.data.code,
        });
      })
      .catch(() => {
        toast.error('Erro de sistema - busca dados do dependente');
        setLoading(false);
      });
  };

  return (
    <>
      <GrantedPageContainer>
        <Title>Sua Conta família</Title>
        <Description>
          <p>
            Você faz parte de uma família Globo. Isso significa que você e
            outros dependentes podem aproveitar os benefícios compartilhados
            pelo titular da família.{' '}
            <Link href="http://globo.com/ajudacontafamilia" target="_blank">
              Saiba mais.
            </Link>
          </p>

          <div className="ownerContainer">
            {picture ? (
              <Avatar src={picture} alt={ownerName} />
            ) : (
              <LetterAvatar>{getFirstLetter(ownerName)}</LetterAvatar>
            )}
            <div>
              <Name>{ownerName} </Name>
              <SubTitle>Titular da Conta familia </SubTitle>
            </div>
          </div>
        </Description>
        <SubContent>
          {' '}
          Veja os benefícios que {ownerName.split(' ')[0]} compartilhou com você
          e os outros membros da família
        </SubContent>

        {productList !== []
          ? productList.map(product => (
              <ProductContent key={product.id}>
                <Img src={product.logo} />
                <p>{product.description}</p>
              </ProductContent>
            ))
          : ''}
        <div className="divButton">
          <Button width="242px" variant="danger" onClick={leave}>
            Sair da Família
          </Button>
        </div>

        {leaveModal.show && (
          <LeaveModal
            user={user}
            modal={leaveModal}
            loading={loading}
            setModal={setLeaveModal}
            setLoading={setLoading}
            close={closeModal}
          />
        )}
      </GrantedPageContainer>
    </>
  );
};

export default GrantedPage;
