import React, { useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import axios, { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { useAuth } from '../../../hooks/useAuth';
import { Actions, Categories } from '../../../types/gtm';
import Icon from '../../../components/Section/Icon';
import facebookIcon from '../../../static/img/facebook-icon.svg';
import googleIcon from '../../../static/img/google-icon.svg';
import { getStatusColor } from '../../../util/StyleAvatar';
import {
  Content,
  NameAccount,
  StatusAccount,
  StyleSubTitle,
  StyleTitle,
  SubTitle,
} from '../styles';
import Toogle from '../../../components/Section/Toogle';
import { api } from '../../../services/api';
import { Accounts } from '../../../types/Accounts';
import environmentConfig from '../../../config/environment-configs';
import Title from '../../../components/Title';

export type ManageAccounts = {
  contasInfo: Accounts;
};

interface GoogleTokenResponse {
  /* eslint-disable camelcase */
  access_token: string;
}
export interface GoogleUserInfo {
  sub: number;
  picture: string;
  email: string;
}

const BoxProviders = ({ contasInfo }: ManageAccounts) => {
  const { authenticateOnAPI, setError, setSuccess, user } = useAuth();
  const [connectionOn] = useState<string>('Conectado');
  const [connectionOff] = useState<string>('Não conectado');
  const [facebookAssociated, setFacebookAssociated] = useState<boolean>(false);
  const [googleAssociated, setGoogleAssociated] = useState<boolean>(false);
  const clientGoogle = useRef<any>(null);

  const associateGoogleAccount = async (
    googleUserId: string,
    photoUrl: string,
    emailOnProvider: string,
  ) => {
    const body = {
      external_id: googleUserId,
      photo_url: photoUrl,
      provider: 'google',
      email_on_provider: emailOnProvider,
    };
    const token = await authenticateOnAPI();
    api
      .post('/accounts/google-account', body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setSuccess('Sua conta Google foi associada.');
          setGoogleAssociated(true);
          window.dataLayer.push({
            event: 'customEventUserInteraction',
            event_category: Categories.AssociateAccounts,
            event_action: Actions.ClickGoogleAssociate,
            bd_suser_code: user?.globoId,
          });
        }
      })
      .catch((error: AxiosError) => {
        window.dataLayer.push({
          event: 'customEventUserInteraction',
          event_category: Categories.AssociateAccounts,
          event_action: Actions.Error,
          event_label: Actions.ErrorAddGoogle,
          bd_suser_code: user?.globoId,
        });
        setGoogleAssociated(false);
        if (error.response?.status === 409) {
          setError(
            'Não foi possível associar sua conta Google, pois ela já encontra-se associada a outra conta de usuário.',
          );
        } else {
          setError(
            'Não foi possível associar sua conta Google, aguarde alguns instantes e tente novamente.',
          );
        }
      });
  };
  const connectGoogleAccount = async (response: GoogleTokenResponse) => {
    const msgError =
      'Não foi possível associar sua conta Google, aguarde alguns instantes e tente novamente.';

    if (response && response.access_token) {
      axios
        .get<GoogleUserInfo>('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${response.access_token}` },
        })
        .then(({ data }) => {
          associateGoogleAccount(data.sub.toString(), data.picture, data.email);
        })
        .catch(() => {
          toast.error(msgError);
        });
    } else {
      toast.error(msgError);
    }
  };
  const setFbAsyncInit = () => {
    window.fbAsyncInit = () => {
      FB.init({
        appId: environmentConfig.FACEBOOK_APP_ID,
        xfbml: false,
        version: 'v13.0',
        status: true,
      });
    };

    ((d, s, id) => {
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.setAttribute('src', '//connect.facebook.net/pt_BR/sdk.js');
      const fjs = d.getElementsByTagName(s)[0];
      if (fjs) {
        fjs?.parentNode?.insertBefore(js, fjs);
      }
    })(document, 'script', 'facebook-jssdk');
  };
  function setAssociations() {
    if (contasInfo.facebook.userId) {
      setFacebookAssociated(true);
    }
    if (contasInfo.google.userId) {
      setGoogleAssociated(true);
    }
  }
  useEffect(() => {
    setAssociations();
    setFbAsyncInit();
    if (typeof google !== 'undefined') {
      const client = google.accounts.oauth2.initTokenClient({
        client_id: environmentConfig.GOOGLE_CLIENT_ID,
        scope: `openid profile email`,
        callback: connectGoogleAccount,
        error_callback: () => {
          toast.error(
            'Não foi possível associar sua conta Google, aguarde alguns instantes e tente novamente.',
          );
        },
      });
      clientGoogle.current = client;
    }
  }, []);
  const associateFacebookAccount = async (
    fbAppId: string,
    fbUserId: string,
    emailOnFacebook: string,
  ) => {
    const body = {
      appId: fbAppId,
      userId: fbUserId,
      email: emailOnFacebook,
    };
    const token = await authenticateOnAPI();
    api
      .post('/accounts/facebook-account', body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setSuccess('Seu Facebook foi associado.');
          setFacebookAssociated(true);
          const label = moment().format('DD/MM/YYYY');
          window.dataLayer.push({
            event: 'customEventUserInteraction',
            event_category: Categories.AssociateAccounts,
            event_action: Actions.ClickAddFacebookAccount,
            event_label: label,
            bd_suser_code: user?.globoId,
          });
        }
      })
      .catch((error: AxiosError) => {
        window.dataLayer.push({
          event: 'customEventUserInteraction',
          event_category: Categories.AssociateAccounts,
          event_action: Actions.Error,
          event_label: Actions.ErrorAddFacebook,
          bd_suser_code: user?.globoId,
        });
        if (error.response?.status === 409) {
          if (
            error.response.data.includes(
              'User already has FacebookID associated',
            )
          ) {
            toast.error(
              'Não foi possível associar este perfil de Facebook, pois este usuário já possui um perfil associado.',
            );
            setFacebookAssociated(false);
          } else {
            toast.error(
              'Não foi possível associar este perfil de Facebook pois ele já se encontra associado a outra conta de usuário.',
            );
            setFacebookAssociated(false);
          }
        } else {
          setFacebookAssociated(false);
          toast.error(
            'Não foi possível associar seu Facebook, aguarde alguns instantes e tente novamente.',
          );
        }
      });
  };
  const connectFacebookAccount = () => {
    if (typeof FB === 'undefined') {
      toast.error(
        'Não foi possível associar seu Facebook, aguarde alguns instantes e tente novamente.',
      );
      return;
    }
    FB.login(
      (response: fb.StatusResponse) => {
        if (response.status === 'connected' && response.authResponse) {
          FB.api(
            '/me?fields=id,picture.type(large),email',
            (responseApi: any) => {
              associateFacebookAccount(
                environmentConfig.FACEBOOK_APP_ID,
                responseApi.id,
                responseApi.email,
              );
            },
          );
        } else {
          toast.error(
            'Não foi possível associar seu Facebook, aguarde alguns instantes e tente novamente.',
          );
        }
      },
      { scope: 'public_profile,email,user_friends' },
    );
  };
  const disassociateFacebookAccount = async () => {
    const token = await authenticateOnAPI();
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    api
      .delete('/accounts/facebook-account', { headers })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setSuccess('Seu Facebook foi desassociado.');
          setFacebookAssociated(!facebookAssociated);
          const label = moment().format('DD/MM/YYYY');
          window.dataLayer.push({
            event: 'customEventUserInteraction',
            event_category: Categories.AssociateAccounts,
            event_action: Actions.ClickRemoveFacebookAccount,
            event_label: label,
            bd_suser_code: user?.globoId,
          });
        }
      })
      .catch(() => {
        setFacebookAssociated(facebookAssociated);
        toast.error(
          'Não foi possível desassociar seu Facebook, aguarde alguns instantes e tente novamente.',
        );
      });
  };
  const HandleFacebook = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;

    if (target.checked) {
      connectFacebookAccount();
    } else {
      disassociateFacebookAccount();
    }
  };
  const disassociateGoogleAccount = async () => {
    const token = await authenticateOnAPI();
    const data = {
      provider: 'google',
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    api
      .delete('/accounts/google-account', { headers, data })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setSuccess('Sua conta Google foi desassociada.');
          setGoogleAssociated(!googleAssociated);
          window.dataLayer.push({
            event: 'customEventUserInteraction',
            event_category: Categories.AssociateAccounts,
            event_action: Actions.ClickGoogleDisassociate,
            bd_suser_code: user?.globoId,
          });
        }
      })
      .catch(() => {
        setGoogleAssociated(googleAssociated);
        toast.error(
          'Não foi possível desassociar sua conta Google, aguarde alguns instantes e tente novamente.',
        );
      });
  };
  const HandleGoogle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const label = moment().format('DD/MM/YYYY');

    if (target.checked) {
      window.dataLayer.push({
        event: 'customEventUserInteraction',
        event_category: Categories.AssociateAccounts,
        event_action: Actions.ClickAddGoogleAccount,
        event_label: label,
        bd_suser_code: user?.globoId,
      });
      clientGoogle.current?.requestAccessToken();
    } else {
      window.dataLayer.push({
        event: 'customEventUserInteraction',
        event_category: Categories.AssociateAccounts,
        event_action: Actions.ClickRemoveGoogleAccount,
        event_label: label,
        bd_suser_code: user?.globoId,
      });
      disassociateGoogleAccount();
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
        pauseOnHover={false}
      />
      <StyleTitle>
        <Title>Contas associadas</Title>
        <p>
          Aqui você pode conectar e desconectar suas contas de apps e sites
          associados com a sua Conta Globo.
        </p>
      </StyleTitle>
      <div role="group" aria-label="Lista 2 itens">
        <StyleSubTitle>
          <SubTitle>Conectou à Conta Globo</SubTitle>
          <p>
            Estes são os apps e sites que você utilizou ou pode utilizar para
            conectar-se à Conta Globo
          </p>
        </StyleSubTitle>
        <Content>
          <div>
            <div>
              <Icon svg={facebookIcon} alt="facebook-icon" />
              <div>
                <NameAccount
                  aria-label={`Facebook ${
                    facebookAssociated ? connectionOn : connectionOff
                  }`}
                >
                  Facebook
                </NameAccount>
                <StatusAccount
                  statusColor={getStatusColor(
                    facebookAssociated ? connectionOn : connectionOff,
                  )}
                >
                  <p>{facebookAssociated ? connectionOn : connectionOff}</p>{' '}
                </StatusAccount>
              </div>
            </div>
            <Toogle
              checked={facebookAssociated}
              onChange={HandleFacebook}
              htmlFor="facebook-toogle"
              aria-label={
                googleAssociated ? `Desativar conexão` : `Adicionar conexão`
              }
            />
          </div>

          <div>
            <div>
              <Icon svg={googleIcon} alt="google-icon" />
              <div>
                <NameAccount
                  aria-label={`Google ${
                    googleAssociated ? connectionOn : connectionOff
                  }`}
                >
                  Google
                </NameAccount>
                <StatusAccount
                  statusColor={getStatusColor(
                    googleAssociated ? connectionOn : connectionOff,
                  )}
                >
                  <p>{googleAssociated ? connectionOn : connectionOff}</p>{' '}
                </StatusAccount>
              </div>
            </div>
            <Toogle
              checked={googleAssociated}
              onChange={HandleGoogle}
              htmlFor="google-toogle"
              aria-label={
                googleAssociated ? `Desativar conexão` : `Adicionar conexão`
              }
            />
          </div>
        </Content>
      </div>
    </>
  );
};
export default BoxProviders;
