import { toast } from 'react-toastify';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styles';
import Button from '../../../../components/Button';
import { LoadingSpinner } from '../../../../components/Loading/styles';
import Modal from '../../../../components/Modal';
import { ModalProps } from '../GrantedPage/GrantedPage';
import { familyApi } from '../../../../services/family-api';
import { Actions, Categories } from '../../../../types/gtm';

const LeaveModal = ({
  user,
  modal,
  loading,
  setModal,
  setLoading,
  close,
}: ModalProps) => {
  const label = 'Tela Sair da Familia';
  const labelPost = 'Tela Saiu da Familia';

  async function leave() {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.FamilyGranted,
      event_action: Actions.ClickLeaveFamily,
      event_label: label,
      bd_suser_code: user?.globoId,
    });

    setLoading(true);
    const body = {
      OwnerID: modal.globoId,
      Origin: 'Exclusão pelo Dependente',
    };

    await familyApi
      .delete('/v1/fe/user/granted/leave', { data: body })
      .then(() => {
        window.dataLayer.push({
          event: 'customEventUserInteraction',
          event_category: Categories.FamilyGranted,
          event_action: Actions.Success,
          event_label: labelPost,
          bd_suser_code: user?.globoId,
        });

        setLoading(false);
        setModal({ ...modal, alreadyRequest: true });
      })
      .catch(() => {
        setLoading(false);
        toast.error('Erro de sistema - falha ao sair da família');
        close(false);
      });
  }

  return (
    <Modal header="Sair da família?">
      <ModalBody>
        {!modal.alreadyRequest ? (
          <>
            {(() => {
              switch (modal.code) {
                case 101:
                  return (
                    <p>
                      Ao sair, você deixará de ter acesso aos benefícios
                      compartilhados por <b>{modal.name}</b>, incluindo sua
                      caixa de e-mail {modal.email}.
                    </p>
                  );
                case 102:
                  return (
                    <p>
                      Ao sair, você deixará de ter acesso aos benefícios
                      compartilhados por <b>{modal.name}</b>, incluindo o
                      provedor de banda larga.
                    </p>
                  );
                case 103:
                  return (
                    <p>
                      Ao sair, você deixará de ter acesso aos benefícios
                      compartilhados por <b>{modal.name}</b>, incluindo sua
                      caixa de e-mail {modal.email} e o provedor de banda larga.
                    </p>
                  );
                default:
                  return (
                    <p>
                      Ao sair, você deixará de ter acesso aos benefícios
                      compartilhados por <b> {modal.name} </b>.
                    </p>
                  );
              }
            })()}
            <p>
              Além disso, você não poderá voltar para a família a não ser que
              seja convidado(a) novamente.
            </p>
          </>
        ) : (
          <p>Você saiu da família.</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          variant="primary"
          onClick={() =>
            close(
              modal.alreadyRequest,
              modal.alreadyRequest ? labelPost : label,
            )
          }
        >
          Fechar
        </Button>
        {!modal.alreadyRequest && (
          <Button variant="secondary" width="100%" onClick={() => leave()}>
            {loading ? <LoadingSpinner isButton /> : 'Sair da família'}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default LeaveModal;
