import styled from 'styled-components';
import { toast } from 'react-toastify';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styles';
import Input from '../../../../components/Form/Input';
import Button from '../../../../components/Button';
import { LoadingSpinner } from '../../../../components/Loading/styles';
import Modal from '../../../../components/Modal';
import { ModalProps } from '../OwnerPage/OwnerPage';
import { familyApi } from '../../../../services/family-api';
import { Actions, Categories } from '../../../../types/gtm';

const DivInputEmail = styled.div`
  display: grid;
  > span {
    font-size: 13px;
    color: #666666;
  }
`;

const SpanError = styled.p`
  color: red;
`;

const InviteModal = ({
  user,
  modal,
  loading,
  setModal,
  setLoading,
  close,
}: ModalProps) => {
  const label = 'Tela Enviar Convite';
  const labelPost = 'Tela Convite Enviado';

  async function send() {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.FamilyOwner,
      event_action: Actions.ClickSendInvite,
      event_label: label,
      bd_suser_code: user?.globoId,
    });

    setLoading(true);
    setModal({ ...modal, error: '' });

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!modal.email?.match(emailRegex)) {
      setLoading(false);
      setModal({
        ...modal,
        error: 'Para prosseguir é necessário digitar um e-mail.',
      });

      window.dataLayer.push({
        event: 'customEventUserInteraction',
        event_category: Categories.FamilyOwner,
        event_action: Actions.Error,
        event_label: modal.email
          ? 'E-mail Invalido - Enviar Convite'
          : 'E-mail Nao Inserido - Enviar Convite',
        bd_suser_code: user?.globoId,
      });

      return;
    }

    await familyApi
      .post('/v1/fe/user/invite', {
        email: modal.email,
      })
      .then(response => {
        window.dataLayer.push({
          event: 'customEventUserInteraction',
          event_category: Categories.FamilyOwner,
          event_action: Actions.Success,
          event_label: labelPost,
          bd_suser_code: user?.globoId,
        });

        setLoading(false);
        setModal({ ...modal, alreadyRequest: true });

        if (response.status !== 200) {
          let err = 'Ocorreu um erro ao enviar o convite';
          if (response.data.customError) {
            err = response.data.customError.errorMessage;
          }

          toast.error(err);
          close(false);
        }
      })
      .catch(e => {
        setLoading(false);

        let err = 'Ocorreu um erro ao enviar o convite';
        if (e.response?.data?.customError) {
          const regex = /(<([^>]+)>)/gi;
          err = e.response.data.customError.errorMessage.replace(regex, '');
        }

        toast.error(err);
        close(false);
      });
  }

  return (
    <Modal
      header={
        !modal.alreadyRequest ? 'Adicionar dependente?' : 'Convite enviado'
      }
    >
      <ModalBody>
        {!modal.alreadyRequest ? (
          <>
            <p>
              Insira o e-mail da pessoa que você deseja convidar para sua
              família:
            </p>
            <DivInputEmail>
              <Input
                name="emailInvite"
                placeHolderText="E-mail do dependente"
                onChange={e =>
                  setModal({
                    ...modal,
                    email: e.currentTarget.value.trim(),
                  })
                }
              />
              <SpanError>{modal.error}</SpanError>
            </DivInputEmail>
          </>
        ) : (
          <>
            <p>
              O convite foi enviado com sucesso para
              <b> {modal.email}</b>.
            </p>
            <p>E agora?</p>
            <p>
              1. Seu familiar receberá um link com o convite pelo e-mail
              indicado.
            </p>
            <p>
              2. Será necessário fazer login ou criar uma Conta Globo através do
              link para aceitar o convite.
            </p>
            <p>
              3. Cada familiar acessa os benefícios compartilhados da família
              com sua própria Conta Globo, de forma segura e personalizada.
            </p>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          variant="primary"
          onClick={() =>
            close(
              modal.alreadyRequest,
              modal.alreadyRequest ? labelPost : label,
            )
          }
        >
          Fechar
        </Button>
        {!modal.alreadyRequest && (
          <Button variant="secondary" width="100%" onClick={() => send()}>
            {loading ? <LoadingSpinner isButton /> : 'Enviar convite'}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default InviteModal;
