import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { ParentalConsentContext } from '../index';
import { useAuth } from '../../../hooks/useAuth';
import { Actions, Categories } from '../../../types/gtm';
import { api } from '../../../services/api';
import environmentConfig from '../../../config/environment-configs';
import { Loading } from '../../../components/Loading';

const actionMap: Record<string, string> = {
  block: 'Bloquear',
  unblock: 'Desbloquear',
  delete: 'Remover',
};

const SubmitConfirm = () => {
  const { authenticateOnAPI, user } = useAuth();
  const { step, setStep } = useContext(ParentalConsentContext);
  const { confirmID, underageId } =
    useParams<{ confirmID: string; underageId: string }>();

  function getActionEventGABlock() {
    switch (step.type) {
      case 'unblock':
        return Actions.UnblockSuccess;
      case 'delete':
        return Actions.ExclusionSuccess;
      default:
        return Actions.BlockSuccess;
    }
  }
  const index = step.type || 'block';
  const action = actionMap[index];

  const getRecaptchaToken = async () => {
    return new Promise(resolve => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(environmentConfig.GOOGLE_RECAPTCHA_KEY, {
              action: 'consentimentoParentalConfirmarSenhaMinhaConta',
            })
            .then((token: string) => {
              resolve(token);
            });
        });
      }
    });
  };

  const submitConfirm = async (type: string, dateToBlock: string) => {
    const token = await authenticateOnAPI();
    const recaptchaToken = await getRecaptchaToken();
    api
      .put(
        `/parental-consent/${underageId}/${type}`,
        { date: dateToBlock || null },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Recaptcha-Token': recaptchaToken,
          },
        },
      )
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          window.dataLayer.push({
            event: 'customEventUserInteraction',
            event_category: Categories.ParentalConsent,
            event_action: getActionEventGABlock(),
            event_label: underageId,
            bd_suser_code: user?.globoId,
          });
          setStep({ screen: 'success', type });
        }
      })
      .catch(() => {
        const error = `Não foi possível ${action.toLowerCase()} a conta.`;
        setStep({ screen: 'error', type: '' });

        toast.error(error);
      })
      .finally(() => {
        sessionStorage.removeItem(`${confirmID}`);
      });
  };

  useEffect(() => {
    interface IConfirmData {
      stepType: string;
      dateToBlock: string;
    }
    if (confirmID) {
      const confirmData: IConfirmData =
        sessionStorage.getItem(`${confirmID}`) &&
        JSON.parse(sessionStorage.getItem(`${confirmID}`) || '');
      if (confirmData) {
        submitConfirm(confirmData.stepType, confirmData.dateToBlock);
      } else {
        setStep({ screen: 'details', type: '' });
      }
    } else {
      setStep({ screen: 'error', type: '' });
    }
  }, [confirmID]);

  return (
    <>
      <Loading />
    </>
  );
};

export default SubmitConfirm;
