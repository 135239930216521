import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import environmentConfig from './config/environment-configs';

import { AuthProvider } from './hooks/useAuth';

import history from './config/history';

import { Routes } from './config/routes';

export function App() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://www.google.com/recaptcha/api.js?render=${environmentConfig.GOOGLE_RECAPTCHA_KEY}`;
    script.async = true;
    document.body.appendChild(script);

    const LGPD_LIB = document.createElement('script');
    LGPD_LIB.src = environmentConfig.LGPD_LIB;
    document.body.appendChild(LGPD_LIB);

    const LGPD_LIB_CSS = document.createElement('link');
    LGPD_LIB_CSS.type = 'text/css';
    LGPD_LIB_CSS.rel = 'stylesheet';
    LGPD_LIB_CSS.href = environmentConfig.LGPD_LIB_CSS;
    document.head.appendChild(LGPD_LIB_CSS);
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-KT2SDGM',
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <AuthProvider>
      <div id="container">
        <Router history={history}>
          <Routes />
        </Router>
      </div>
    </AuthProvider>
  );
}
