import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { Actions, Categories } from '../../types/gtm';
import Container from '../../components/Container';
import Header from '../../components/Header';
import { api } from '../../services/api';
import { Accounts } from '../../types/Accounts';
import { Loading } from '../../components/Loading';
import ManageAccounts from './ManageAccounts';
import ManagePartners from './ManagePartners';

const AssociateAccounts = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { authenticateOnAPI, user, logout } = useAuth();

  const [accounts, setAccounts] = useState<Accounts>({
    facebook: {
      appId: '',
      userId: '',
      photoUrl: '',
    },
    google: {
      userId: '',
      externalId: '',
      provider: '',
      login: '',
    },
    partners: [],
  });

  useEffect(() => {
    let isMounted = true;
    const getDataAccounts = async () => {
      setLoading(true);
      const token = await authenticateOnAPI();
      api
        .get('/accounts', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response: AxiosResponse<Accounts>) => {
          if (isMounted) {
            setAccounts(response.data);
          }
        })
        .catch(() => {
          toast.error('Não foi possível buscar contas associadas.');
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getDataAccounts();
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.AssociateAccounts,
      event_action: Actions.Pageview,
      bd_suser_code: user?.globoId,
    });

    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Header
        user={user}
        onLogout={logout}
        showReturn
        screenMenu={Categories.AssociateAccounts}
        onReturn={() => {
          window.dataLayer.push({
            event: 'customEventUserInteraction',
            event_category: Categories.AssociateAccounts,
            event_action: Actions.ClickBack,
            bd_suser_code: user?.globoId,
          });

          history.goBack();
        }}
      />
      <Container>
        <ManageAccounts contasInfo={accounts} />
        <ManagePartners contasInfo={accounts} />
      </Container>
    </>
  );
};

export default AssociateAccounts;
