import { MessageIntervention } from '../components/PopUpIntervention';
import { User } from './user';

export enum Categories {
  Home = 'Minha Conta - Home',
  EditUserEmail = 'Minha Conta - Alterar E-mail',
  EditUserPassword = 'Minha Conta - Alterar Senha',
  EditUserPersonalData = 'Minha Conta - Dados Pessoais',
  Family = 'Minha Conta - Gerenciar Familia',
  FamilyOwner = 'Minha Conta - Gerenciar Familia - Titular',
  FamilyGranted = 'Minha Conta - Gerenciar Familia - Dependente',
  AssociateAccounts = 'Minha Conta - Contas Associadas',
  CommunicationPreferences = 'Minha Conta - Gestão de Comunicados',
  DownloadData = 'Minha Conta - Download de Dados',
  DeleteAccount = 'Minha Conta - Excluir Conta',
  Sessions = 'Minha Conta - Sessões em Dispositivos',
  ParentalConsent = 'Minha Conta - Consentimento Parental',
  CellphoneIntervention = 'Intervencao Celular',
  CellphoneValidationIntervention = 'Intervencao Validacao Celular',
  CPFIntervention = 'Intervencao CPF',
  Interventions = 'Multi Intervention',
}

export enum Actions {
  // ****************** Actions Gerais ******************
  Pageview = 'Pageview',
  Error = 'Erro',
  Success = 'Sucesso',
  ClickMenu = 'Clique Menu',
  ClickLogoutMenu = 'Clique Sair - Menu',
  ClickBack = 'Clique Voltar',
  ClickBlockOption = 'Clique Opcao de Bloqueio',
  ClickBlockAccount = 'Clique Bloquear Conta',
  ClickForgotPassword = 'Clique Esqueceu Senha',
  ClickClose = 'Clique Fechar',
  ClickMore = 'Clique Saiba Mais',
  ClickCancel = 'Clique Cancelar',
  ClickSaveChanges = 'Clique Salvar Alteracoes',

  // ****************** Actions Home ******************
  ClickLogout = 'Clique Sair',
  ClickEditEmail = 'Clique Alterar E-mail',
  ClickAssociateAccounts = 'Clique Gerenciar Contas Associadas',
  ClickEditPassword = 'Clique Alterar Senha',
  ClickEditUser = 'Clique Editar Dados Pessoais',
  ClickManageFamily = 'Clique Gerenciar Família',
  ClickFacebookAssociate = 'Clique Facebook - Associado',
  ClickFacebookDisassociate = 'Clique Facebook - Desassociado',
  ClickGoogleAssociate = 'Clique Google - Associado',
  ClickGoogleDisassociate = 'Clique Google - Desassociado',
  ClickComunicationPreferences = 'Clique Controlar Comunicados',
  ClickParentalConsent = 'Clique Conta - Consentimento Parental',
  ClickDownloadData = 'Clique Download de Dados',
  ClickDeleteAccount = 'Clique Excluir Conta Globo',
  ClickDisconnectAll = 'Clique Desconectar Todos',
  ClickEditAssignatures = 'Clique Ver Pagamentos',
  ClickAssignNewProducts = 'Clique Assinar Novos Produtos',
  ClickDisconnectSession = 'Clique Desconectar Sessão',
  WidgetView = 'Widget View',
  AmountOfCards = 'Quantidade de Cards',
  DragCard = 'Arrastar Cards',
  ClickRightArrow = 'Clique Seta para Direita',
  ClickLeftArrow = 'Clique Seta para Esquerda',

  // ****************** Actions Gestão de Comunicados ******************
  ClickActivateCommunication = 'Clique Ativar Comunicados',
  ClickDesactiveCommunication = 'Clique Desativar Comunicados',
  ClickIconCommunication = 'Clique Icone do Produto',
  ClickNameCommunication = 'Clique Nome do Produto',
  ClickIOSCommunication = 'Clique IOS',
  ClickAndroidCommunication = 'Clique Android OS',
  ClickPolicyPrivacyCommunication = 'Clique Política de Privacidade',

  // ****************** Actions Exclusão de Conta ******************
  ClickRedirectGloboCom = 'Clique Acessar Globo.com',
  ClickProceed = 'Clique Prosseguir',
  ClickSubscriptionsManagement = 'Clique Gerenciamento de Assinaturas',

  // ****************** Actions Download de Dados ******************
  ClickDownload = 'Clique Baixar',

  // ****************** Actions Consentimento Parental ******************
  ClickConfirmBlock = 'Clique Confirmar Bloqueio',
  ClickConfirmUnblock = 'Clique Confirmar Desbloqueio',
  ClickConfirmExclusion = 'Clique Confirmar Exclusao',
  BlockSuccess = 'Sucesso no Bloqueio',
  UnblockSuccess = 'Sucesso no Desbloqueio',
  ExclusionSuccess = 'Sucesso na Exclusao',
  ClickBackHome = 'Clique Voltar para Home',
  ClickUnblockAccount = 'Clique Desbloquear Conta',
  ClickDeleteAccountParental = ' Clique Excluir Conta',

  // ****************** Actions Conta Familia ******************
  ClickAddDependent = 'Clique Adicionar Dependente',
  ClickSendInvite = 'Clique Enviar Convite',
  ClickResendInvite = 'Clique Reenviar Convite',
  ClickCancelInvite = 'Clique Cancelar Convite',
  ClickRemoveDependent = 'Clique Remover Dependente',
  ClickLeaveFamily = 'Clique Sair da Familia',
  FamilyComplete = 'Familia Completa',

  // ****************** Actions Alteração de senha ******************
  SignOutAllSessions = 'Sair da Conta em Todos os Dispositivos',

  // ****************** Actions Alteração de E-mail ******************
  PendingConfirmation = 'Confirmacao Pendente',

  // ****************** Actions Intervenção ******************
  InterventionAlert = 'Alerta Intervencao',
  ClickRegister = 'Clique Cadastrar',
  ClickChange = 'Clique Alterar',
  ClickContinue = 'Clique Continuar',
  ClickConclude = 'Clique Concluir',

  // ****************** Actions Confirmação de celular ******************
  ClickChangeNumber = 'Clique Alterar Numero Cadastrado',
  ClickResendToken = 'Clique Reenviar Codigo',
  ClickConfirmCellPhone = 'Clique Confirmar Codigo',
  CodeSent = 'Codigo Enviado',
  CodeResend = 'Codigo Reenviado',
  CellPhoneRegistred = 'Celular Cadastrado',
  ClickSkip = 'Clique Deixar para Depois',

  // ****************** Actions CPF ******************
  InterventionCPF = 'Intervencao CPF',

  // ****************** Actions Confirmação de celular ******************
  ClickAddFacebookAccount = 'Clique Adicionar Conta - Facebook',
  ClickRemoveFacebookAccount = 'Clique Remover Conta - Facebook',
  ClickAddGoogleAccount = 'Clique Adicionar Conta - Google',
  ClickRemoveGoogleAccount = 'Clique Remover Conta - Google',
  ClickAddPartnerAccount = 'Clique Conectar ao Parceiro - ',
  ClickRemovePartnerAccount = 'Clique Desconectar do Parceiro - ',
  ErrorConnectingPartner = 'Erro ao Conectar no Parceiro - ',
  ErrorAddFacebook = 'Erro ao Adicionar Conta - Facebook',
  ErrorAddGoogle = 'Erro ao Adicionar Conta - Google',
  DataSharing = 'Compartilhamento de Dados',
}

export enum Labels {
  Home = 'Home',

  // ****************** Labels Exclusão de Conta ******************
  DeleteAccountPage = 'Tela Pedido de Exclusao de Conta',
  DeleteAccountPageInfo = 'Tela Informativa Inicial',
  DeleteAccountPageReasons = 'Tela Motivos',

  // ****************** Labels Consentimento Parental ******************
  SevenDays = '7 dias',
  FifteenDays = '15 dias',
  ThirtyDays = '30 dias',
  UntilUnblock = 'Até eu desbloquear',
  FromTodayUntil = 'De hoje até',
  BlockScreen = 'Tela Bloqueio',
  UnblockScreen = 'Tela Desbloqueio',
  ExclusionScreen = 'Tela Exclusao',
  IncorrectPassword = 'Senha Incorreta',
  NullPassword = 'Senha Em Branco',
  BlockSuccessScreen = 'Tela Sucesso no Bloqueio',
  UnblockSuccessScreen = 'Tela Sucesso no Desbloqueio',
  ExclusionSuccessScreen = 'Tela Sucesso na Exclusao',

  // ****************** Labels Alteração de senha ******************
  CurrentPasswordInvalid = 'Senha Atual Invalida',
  NewPasswordSameCurrentPassword = 'Senha Igual Atual',
  PasswordNotChanged = 'Senha nao alterada',
  SuccessChangedPassword = 'Senha alterada com sucesso',

  // ****************** Labels Alteração de e-mail ******************
  EmailNotChanged = 'E-mail Igual Atual',
  EmailInUse = 'E-mail em Uso',
  EmailInvalid = 'E-mail Invalido',
  GenericError = 'Erro Generico',
  PendingConfirmationNotificationScreen = 'Tela Notificacao de Confirmacao Pendente',

  // ****************** Labels Carrossel de Vendas - Vitrine ******************
  SalesCarouselScreen = 'Vitrine - Carrossel de Vendas',

  RequestDataScreen = 'Tela Solicitacao Acesso aos Dados - ',
  RemoveConnectionScreen = 'Tela Remover Conexao - ',
  ConnectedPartnerScreen = 'Tela Conectado ao Parceiro - ',
  DisconnectedPartnerScreen = 'Tela Desconectado Do Parceiro - ',

  // ****************** Labels Intervenção CPF ******************
  InterventionScreen = 'Tela de Intervencao',
  InconsistentData = 'Dados Inconsistentes',
  NonexistentCPF = 'CPF Inexistente',
  InvalidFormat = 'CPF com Formato Invalido',
  AttemptLimitReached = 'Limite de Tentativas Atingido',
  SystemTimeOut = 'Sistema Fora do Ar',
  ErrorOnScreenValidationCPF = 'Tela de Erro na Validacao de CPF',
  RegisteredCPF = 'CPF Cadastrado',
  Error = 'Erro',
  ScreenConfirmDocument = 'Tela de Confirmacao de CPF',
  CPFNotChanged = 'CPF não alterado',
}

function hasCellphone(user: User) {
  return user.phones && user.phones.cellPhone && user.phones.dddCellPhone;
}

export function GetInterventionCategory(
  user?: User,
  intervention?: MessageIntervention,
) {
  if (
    intervention?.type === 'docNumber' ||
    intervention?.type === 'confirmDocNumber'
  ) {
    return Categories.CPFIntervention;
  }
  if (!user || (!hasCellphone(user) && intervention?.type === 'cellphone')) {
    return Categories.CellphoneIntervention;
  }
  if (hasCellphone(user) || intervention?.type === 'confirmMobilePhone') {
    return Categories.CellphoneValidationIntervention;
  }
  return Categories.Interventions;
}
