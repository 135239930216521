import { User } from '../../types/user';
import {
  ContainerHeaderUser,
  AvatarPicture,
  Elipse,
  Pencil,
  EditAvatarIcon,
} from './styles';
import { Categories, Actions, Labels } from '../../types/gtm';
import Action from '../Section/Action';
import editIcon from '../../static/img/edit.svg';
import environmentConfig from '../../config/environment-configs';

interface HeaderUserProps {
  user: User;
  onLogout: () => void;
}

const HeaderUser = ({ user, onLogout }: HeaderUserProps) => {
  window.dataLayer = window.dataLayer || [];

  const logout = () => {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.Home,
      event_action: Actions.ClickLogout,
      event_label: Labels.Home,
      bd_suser_code: user?.globoId,
    });

    if (onLogout) {
      onLogout();
    }
  };

  return (
    <ContainerHeaderUser>
      <div>
        <Action
          onClick={() => {}}
          href={environmentConfig.AVATAR_ID_URL}
          theme="link"
        >
          <EditAvatarIcon>
            <AvatarPicture src={user.photoURL} alt="avatar de usuário" />
            <Elipse>
              <Pencil src={editIcon} alt="editar avatar" />
            </Elipse>
          </EditAvatarIcon>
        </Action>
        <p>{user.fullName}</p>
        <button
          aria-label="Sair da sua Conta Globo"
          type="button"
          onClick={logout}
        >
          Sair
        </button>
      </div>
    </ContainerHeaderUser>
  );
};

export default HeaderUser;
