import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useAuth } from '../../../hooks/useAuth';
import useMedia from '../../../hooks/useMedia';
import { Actions, Categories, Labels } from '../../../types/gtm';
import Icon from '../../../components/Section/Icon';
import Alert from '../../../static/img/alert-account.svg';
import getAvatarColor, { getStatusColor } from '../../../util/StyleAvatar';
import {
  Content,
  ContentPartner,
  DivAvatarPicture,
  NameAccount,
  StatusAccount,
  StylePartner,
  StyleSubTitle,
  SubTitle,
  TitleModal,
} from '../styles';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { ModalBody, ModalFooter } from '../../../components/Modal/styles';
import Toogle from '../../../components/Section/Toogle';
import { api } from '../../../services/api';
import { Accounts, Partner } from '../../../types/Accounts';

export type ManagePartners = {
  contasInfo: Accounts;
};

const BoxProviders = ({ contasInfo }: ManagePartners) => {
  const [connectionOn] = useState<string>('Conectado');
  const [connectionOff] = useState<string>('Não conectado');
  const [associateModalOpen, setAssociateModalOpen] = useState<boolean>(false);
  const [partner, setPartner] = useState<Partner>();
  const { authenticateOnAPI, user } = useAuth();
  const history = useHistory();
  const mobile = useMedia('(max-width: 650px)');

  function formatDate(createdAt: Date) {
    const currentDate = new Date(createdAt);
    const months = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ];
    const date = `${currentDate.getDate()} de ${
      months[currentDate.getMonth()]
    } de ${currentDate.getFullYear()}`;
    return date;
  }

  function formatDateAriaLabel(createdAt: Date) {
    const currentDate = new Date(createdAt);
    const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    const date = `${currentDate.getDate()} de ${
      months[currentDate.getMonth()]
    } de ${currentDate.getFullYear()}`;
    return date;
  }
  const getStatusAriaLabel = (account: any) => {
    if (account.isActive && account.createdAt) {
      return `${connectionOn} - ${formatDateAriaLabel(account.createdAt)}`;
    }
    return `${connectionOff}`;
  };

  const getStatus = (account: any) => {
    if (account.isActive && account.createdAt) {
      return <p>{connectionOn}</p>;
    }
    return <p>{connectionOff}</p>;
  };

  const getDateStatus = (account: any) => {
    if (account.isActive && account.createdAt) {
      return <p>Conectou {formatDate(account.createdAt)}</p>;
    }

    return null;
  };

  function eventGA() {
    const label = moment().format('DD/MM/YYYY');
    if (partner?.isActive) {
      window.dataLayer.push({
        event: 'customEventUserInteraction',
        event_category: Categories.AssociateAccounts,
        event_action: Actions.ClickRemovePartnerAccount + partner?.company,
        event_label: label,
        bd_suser_code: user?.globoId,
      });
    } else {
      window.dataLayer.push({
        event: 'customEventUserInteraction',
        event_category: Categories.AssociateAccounts,
        event_action: Actions.ClickAddPartnerAccount + partner?.company,
        event_label: label,
        bd_suser_code: user?.globoId,
      });
    }
  }

  function erroConnectingPartnerGA() {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.AssociateAccounts,
      event_action: Actions.Error,
      event_label: Actions.ErrorConnectingPartner + partner?.company,
      bd_suser_code: user?.globoId,
    });
  }

  function eventGADataSharing() {
    const infoLabel = partner?.sharedTranslated.join();
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.AssociateAccounts,
      event_action: Actions.DataSharing,
      event_label: `${infoLabel} - ${partner?.company}`,
      bd_suser_code: user?.globoId,
    });
  }

  const associateAccount = async () => {
    eventGA();
    const body = {
      type: partner?.type,
      event: !partner?.isActive,
      goalId: partner?.goalId,
      partnerServiceId: partner?.partnerServiceId,
      shared: partner?.shared,
    };
    const token = await authenticateOnAPI();
    api
      .post('/accounts/partner-account', body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          window.dataLayer.push({
            event: 'customEventUserInteraction',
            event_category: Categories.AssociateAccounts,
            event_action: Actions.Success,
            event_label: !partner?.isActive
              ? Labels.ConnectedPartnerScreen + partner?.company
              : Labels.DisconnectedPartnerScreen + partner?.company,
            bd_suser_code: user?.globoId,
          });

          history.push({
            pathname: '/manager-accounts',
            state: {
              nameAccount: partner?.company,
              activeAccount: partner?.isActive,
            },
          });
        } else {
          toast.error(
            `Não foi possível ${
              !partner?.isActive ? 'associar' : 'desassociar'
            } a conta.`,
          );
          erroConnectingPartnerGA();
        }
      })
      .catch(() => {
        erroConnectingPartnerGA();
        toast.error(
          `Ocorreu um erro ao ${
            !partner?.isActive ? 'associar' : 'desassociar'
          }`,
        );
      })
      .finally(() => {
        setAssociateModalOpen(false);
      });
  };
  function AssociateAccountModal() {
    return (
      <Modal
        contentWidth={mobile ? '100%' : '400px'}
        contentHeight={!partner?.isActive ? 'auto' : '580px'}
      >
        <ModalBody>
          {!partner?.isActive ? (
            <>
              <TitleModal>
                <DivAvatarPicture
                  avatarSize="64px"
                  backgroundColor={getAvatarColor(
                    partner ? partner.company.charAt(0) : '',
                  )}
                >
                  <p aria-hidden>{partner ? partner.company.charAt(0) : ''}</p>
                </DivAvatarPicture>
                <p>
                  <b>{partner?.company}</b> está solicitando acesso aos
                  seguintes dados:
                </p>
              </TitleModal>
              <ContentPartner>
                <StylePartner>
                  <ul>
                    {partner?.sharedTranslated?.map(item => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </StylePartner>
                <StylePartner>
                  <p>Deseja continuar?</p>
                </StylePartner>
              </ContentPartner>
            </>
          ) : (
            <>
              <TitleModal>
                <Icon svg={Alert} />
                <p>Tem certeza que deseja remover esta conexão?</p>
              </TitleModal>
              <ContentPartner>
                <StylePartner>
                  Isso removerá todas as suas conexões com{' '}
                  <b>{partner?.company}</b> criadas para fazer login.
                </StylePartner>
                <StylePartner>
                  <b>{partner?.company}</b> não poderá mais acessar seus dados
                  compartilhados anteriormente com ele, mas é possível que ele
                  ainda tenha informações que recebeu quando a conexão foi
                  realizada.
                </StylePartner>
                <StylePartner>Deseja continuar?</StylePartner>
              </ContentPartner>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            width="100%"
            onClick={() => {
              window.dataLayer.push({
                event: 'customEventUserInteraction',
                event_category: Categories.AssociateAccounts,
                event_action: Actions.ClickContinue,
                event_label: !partner?.isActive
                  ? Labels.RequestDataScreen + partner?.company
                  : Labels.RemoveConnectionScreen + partner?.company,
                bd_suser_code: user?.globoId,
              });
              eventGADataSharing();
              associateAccount();
            }}
          >
            Continuar
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              window.dataLayer.push({
                event: 'customEventUserInteraction',
                event_category: Categories.AssociateAccounts,
                event_action: Actions.ClickCancel,
                event_label: !partner?.isActive
                  ? Labels.RequestDataScreen + partner?.company
                  : Labels.RemoveConnectionScreen + partner?.company,
                bd_suser_code: user?.globoId,
              });

              setAssociateModalOpen(false);
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
        pauseOnHover={false}
      />
      {contasInfo.partners.length > 0 && (
        <div
          role="group"
          aria-label={`Lista ${contasInfo.partners.length} itens`}
        >
          <StyleSubTitle role="group">
            <SubTitle>Conectou usando a Conta Globo</SubTitle>
            <p>
              Estes são os apps e sites que você conectou ou pode se conectar
              usando a Conta Globo
            </p>
          </StyleSubTitle>
          <Content>
            {contasInfo.partners.map(partnerSelected => (
              <div key={partnerSelected.id}>
                <div>
                  <DivAvatarPicture
                    avatarSize="32px"
                    backgroundColor={getAvatarColor(
                      partnerSelected.company.charAt(0),
                    )}
                  >
                    <p aria-hidden>{partnerSelected.company.charAt(0)}</p>
                  </DivAvatarPicture>
                  <div>
                    <NameAccount
                      aria-label={`${
                        partnerSelected.company
                      } ${getStatusAriaLabel(partnerSelected)}`}
                    >
                      {partnerSelected.company}
                    </NameAccount>
                    <StatusAccount
                      statusColor={getStatusColor(
                        partnerSelected.isActive ? connectionOn : connectionOff,
                      )}
                    >
                      {getStatus(partnerSelected)}
                    </StatusAccount>
                    {getDateStatus(partnerSelected) && (
                      <StatusAccount statusColor="#333333">
                        {getDateStatus(partnerSelected)}
                      </StatusAccount>
                    )}
                  </div>
                </div>
                <Toogle
                  checked={partnerSelected.isActive}
                  onChange={() => {
                    setPartner(partnerSelected);
                    setAssociateModalOpen(true);
                  }}
                  htmlFor="accounts-toogle"
                  aria-label={
                    partnerSelected.isActive
                      ? `Desativar conexão`
                      : `Adicionar conexão`
                  }
                />
                {associateModalOpen && <AssociateAccountModal />}
              </div>
            ))}
          </Content>
        </div>
      )}
    </>
  );
};
export default BoxProviders;
