import styled from 'styled-components';
import { ReactComponent as Trash } from '../../../../static/img/trash.svg';
import {
  ItemContainer,
  Email,
  Container,
  Avatar,
  LetterAvatar,
  CustomButton,
} from '../StylesComponents';
import { ModalState } from '../OwnerPage/OwnerPage';
import { Actions, Categories } from '../../../../types/gtm';
import { User } from '../../../../types/user';

type GrantedListItemProps = {
  user: User;
  globoId: string;
  name: string;
  email: string;
  picture: string;
  setModal: (m: ModalState) => void;
};

const Name = styled.p`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.2px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 4px;
  @media (max-width: 650px) {
    font-size: 14px;
  }
`;

const getFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase();
};

const GrantedListItem = ({
  user,
  picture,
  globoId,
  name,
  email,
  setModal,
}: GrantedListItemProps) => {
  const removeDependent = () => {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.FamilyOwner,
      event_action: Actions.ClickRemoveDependent,
      event_label: 'Tela Inicial',
      bd_suser_code: user?.globoId,
    });

    setModal({ show: true, globoId, name, email });
  };

  return (
    <Container>
      <div>
        {picture ? (
          <Avatar aria-hidden src={picture} alt={name} />
        ) : (
          <LetterAvatar aria-hidden>{getFirstLetter(name)}</LetterAvatar>
        )}
      </div>

      <ItemContainer>
        <Name>{name}</Name>
        <Email>{email}</Email>
      </ItemContainer>
      <CustomButton
        aria-label="Excluir dependente da sua conta família"
        onClick={() => removeDependent()}
      >
        <Trash fill="#005cde" />
      </CustomButton>
    </Container>
  );
};

export default GrantedListItem;
