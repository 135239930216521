import { toast, ToastContainer } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import { useAuth } from '../../hooks/useAuth';
import Container from '../../components/Container';
import Title from '../../components/Title';
import { api } from '../../services/api';
import { Loading } from '../../components/Loading';
import { ServiceCommunication } from '../../types/service';
import Button from '../../components/Button';
import { Actions, Categories } from '../../types/gtm';
import CommunicationPreferenceService from './CommunicationPreferenceService';

const Card = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 1px 2px 0px #00000033;
  background: #ffffff;
  border-radius: 3px;
  width: 100%;
  padding: 0px 16px 0px 16px;
  list-style: none;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  > button {
    &:first-child {
      margin-right: 16px;
    }
    width: 304px;
  }
`;

const SubTitle = styled.h2`
  font-family: 'GlobotipoTexto-Bd';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 40px;
  margin-bottom: 24px;
  @media (max-width: 650px) {
    margin-bottom: 30px;
  }
`;

const Block = styled.div`
  width: 100%;
  > p {
    margin: 0;
    line-height: 21.79px;
    ~ p {
      margin-top: 32px;
    }
    ~ ul {
      margin-top: 32px;
      margin-bottom: 0;
    }
  }
  a {
    text-decoration: none;
    font-weight: bolder;
    color: #005cde;
  }
  ul {
    padding-left: 24px;
    li::marker {
      color: #505050;
      font-size: 12px;
    }
  }
  ${Card} {
    padding: 0px 16px 0px 16px;
  }
`;

const EmailBlock = styled(Block)`
  margin-bottom: 40px;
`;

const SmsComunication = styled.div`
  ul {
    margin-top: 8px;
    margin-left: 0px;
  }
  p {
    margin-top: 16px;
    margin-bottom: 0;
  }
`;

const CommunicationPreferences = () => {
  const { user, authenticateOnAPI, logout } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ServiceCommunication[]>([]);
  const [errorInRequest, setErrorInRequest] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const history = useHistory();

  const getInfo = async (l: boolean) => {
    try {
      setLoading(l);

      const token = await authenticateOnAPI();
      await api
        .get('/communication-preferences', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response: AxiosResponse<ServiceCommunication[]>) => {
          setLoading(false);
          setData(response.data);
        })
        .catch(() => {
          setLoading(false);
          setErrorInRequest(!errorInRequest);
          toast.error('Não foi possível consultar as informações');
        });
    } catch (err) {
      setErrorInRequest(!errorInRequest);
      toast.error('Não foi possível consultar as informações');
      throw new Error('Erro na obtenção dos dados de gestão de comunicação!');
    }
  };

  const communicationPreference = async (
    checked: boolean,
    serviceId: string,
  ) => {
    try {
      setDisabled(true);
      const service = data.find(s => {
        return s.serviceId.toString() === serviceId;
      });

      if (service) {
        const token = await authenticateOnAPI();
        const body = {
          serviceId: service.serviceId,
          event: checked,
          communicationType: service.communicationType,
          buId: service.buId,
          listId: service.listId,
        };

        await api
          .post('/communication-preferences', body, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          })
          .then(() => {
            setDisabled(false);
            setData(servicesList =>
              servicesList.map(s => {
                if (s.serviceId === service.serviceId) {
                  return { ...s, newsletter: checked };
                }
                return s;
              }),
            );
            window.dataLayer.push({
              event: 'customEventUserInteraction',
              event_category: Categories.CommunicationPreferences,
              event_action: checked
                ? Actions.ClickActivateCommunication
                : Actions.ClickDesactiveCommunication,
              event_label: service.name,
              bd_suser_code: user?.globoId,
            });
          });
      }
    } catch (err) {
      setDisabled(false);
      toast.error('Não foi possível editar o valor da comunicação');
    }
  };

  const updatePreferences = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;

    await communicationPreference(target.checked, target.id);
  };

  function handleOSNotification(ios: 'ios' | 'android') {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.CommunicationPreferences,
      event_action:
        ios === 'ios'
          ? Actions.ClickIOSCommunication
          : Actions.ClickAndroidCommunication,
      bd_suser_code: user?.globoId,
    });

    let url =
      'https://support.apple.com/pt-br/HT201925#:~:text=mant%C3%AA%2Dlas%20separadas.-,Acesse%20Ajustes%20%3E%20Notifica%C3%A7%C3%B5es.,base%20nos%20alertas%20do%20app.';
    if (ios === 'android') {
      url = 'https://support.google.com/android/answer/9079661?hl=pt-BR';
    }
    window.open(url, '_blank');
  }

  useEffect(() => {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.CommunicationPreferences,
      event_action: Actions.Pageview,
      bd_suser_code: user?.globoId,
    });
    getInfo(true);
  }, []);

  const urlPrivacyPolicy =
    !user.privacyPolicy || user.privacyPolicy.jurisdiction === 'LGPD'
      ? `https://privacidade.globo.com/privacy-policy`
      : 'https://privacidade.globo.com/int/pt-br/politica-de-privacidade/';

  const redirectToPolicyPrivacy = () => {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.CommunicationPreferences,
      event_action: Actions.ClickPolicyPrivacyCommunication,
      bd_suser_code: user?.globoId,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
        pauseOnHover={false}
      />

      <>
        <Header
          user={user}
          onLogout={logout}
          showReturn
          screenMenu={Categories.CommunicationPreferences}
          onReturn={() => {
            window.dataLayer.push({
              event: 'customEventUserInteraction',
              event_category: Categories.CommunicationPreferences,
              event_action: Actions.ClickBack,
              bd_suser_code: user?.globoId,
            });

            history.goBack();
          }}
        />
        <Container errorInRequest={errorInRequest}>
          <Block>
            <Title>Controlar Comunicados</Title>
            <p>
              Comunicados são mensagens que enviamos a você sobre as suas
              assinaturas ou serviços gratuitos.
            </p>
            <p>
              Eles podem trazer novidades, pesquisas, novos serviços ou
              promoções baseadas no seu interesse pelos nossos conteúdos.
            </p>
          </Block>

          <EmailBlock role="group">
            <SubTitle>Emails</SubTitle>
            <p>
              A seguir, você pode controlar quais comunicados quer manter ou
              desativar:
            </p>
          </EmailBlock>

          <Block>
            <Card>
              {data.map(s => (
                <CommunicationPreferenceService
                  key={s.serviceId}
                  serviceCommunication={s}
                  updatePreferences={updatePreferences}
                  disabled={disabled}
                />
              ))}
            </Card>
          </Block>

          <Block role="group">
            <SubTitle>Mensagens de Texto via SMS</SubTitle>
            <SmsComunication>
              <p>
                Para desativar o recebimento de mensagens por SMS, basta
                responder com o nome do produto junto com a palavra
                &quot;sair&quot;, conforme abaixo:
              </p>
              <ul>
                <li>
                  <strong>Globoplay</strong>: GLOBOPLAYSAIR
                </li>
                <li>
                  <strong>Cartola e Cartola Express</strong>: CARTOLASAIR
                </li>
                <li>
                  <strong>Premiere</strong>: PREMIERESAIR
                </li>
                <li>
                  <strong>Combate</strong>: COMBATESAIR
                </li>
              </ul>

              <p>
                Quando quiser voltar a receber nossas mensagens, responda
                trocando a palavra &quot;sair&quot; ao final por
                &quot;receber&quot; (GLOBOPLAYRECEBER, CARTOLARECEBER,
                PREMIERERECEBER OU COMBATERECEBER).
              </p>
            </SmsComunication>
          </Block>

          <Block role="group">
            <SubTitle>Notificações</SubTitle>
            <p>
              Para controlar as notificações dos nossos aplicativos, acesse as
              instruções oficiais das fabricantes do sistema operacional usado
              em seu dispositivo:
            </p>
          </Block>

          <Block>
            <Buttons>
              <Button onClick={() => handleOSNotification('ios')}>iOS</Button>
              <Button onClick={() => handleOSNotification('android')}>
                Android OS
              </Button>
            </Buttons>
          </Block>

          <Block role="group">
            <SubTitle>Informações essenciais</SubTitle>
            <p>
              Para sermos transparentes e cumprir as leis que protegem sua
              privacidade, em algumas situações nós precisaremos enviar a você
              outros tipos de comunicados.
            </p>
            <p>São comunicados com informações essenciais, importantes para:</p>
            <ul>
              <li>a sua Conta Globo;</li>
              <li>a gestão da sua assinatura;</li>
              <li>as respostas de atendimento às suas solicitações;</li>
              <li>nossa Política de Privacidade;</li>
              <li>nossos Termos de Uso;</li>
              <li>nossos regulamentos.</li>
            </ul>
            <p>
              Para saber mais, consulte a seção de comunicados da nossa{' '}
              <a
                href={urlPrivacyPolicy}
                target="_blank"
                onClick={redirectToPolicyPrivacy}
                rel="noreferrer"
              >
                Política de Privacidade
              </a>
              .
            </p>
          </Block>
        </Container>
      </>
    </>
  );
};

export default CommunicationPreferences;
