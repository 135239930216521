import Content from '../../../../components/Section/Content';
import Box from '../../../../components/Section/Box';
import Icon from '../../../../components/Section/Icon';
import { useAuth } from '../../../../hooks/useAuth';
import { Categories, Actions, Labels } from '../../../../types/gtm';
import Action from '../../../../components/Section/Action';
import svg from '../../../../static/img/icon-open-menu.svg';

const BoxAssociateAccounts = () => {
  const { user } = useAuth();

  const title = 'Contas associadas';
  const describeTitle = 'Gerencie suas contas associadas a conta Globo';
  return (
    <Box title={title} describeTitle={describeTitle}>
      <Content>
        <Action
          onClick={() => {
            window.dataLayer.push({
              event: 'customEventUserInteraction',
              event_category: Categories.Home,
              event_action: Actions.ClickAssociateAccounts,
              event_label: Labels.Home,
              bd_suser_code: user?.globoId,
            });
          }}
          href="#/associate-accounts"
          theme="link"
        >
          <p>Gerenciar contas associadas</p>
          <Icon svg={svg} alt="icon-open-menu" />
        </Action>
      </Content>
    </Box>
  );
};

export default BoxAssociateAccounts;
