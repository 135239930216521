import { useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Button from '../../../components/Button';
import { DivFooter, ParentalConsentContext } from '../index';
import { Actions, Categories } from '../../../types/gtm';
import { useAuth } from '../../../hooks/useAuth';

const DivDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 48px;
`;

const DivBlock = styled.div`
  > p {
    font-weight: bold;
  }
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 500;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #ffffff;
  width: 143px;
  height: 24px;
  padding: 6px;
  margin-top: 16px;
  margin-right: 16px;
  border-radius: 90px;
  background-color: #d10000;

  @media (max-width: 650px) {
    width: 100%;
    margin-right: 0px;
  }
`;

const DivWhen = styled.div`
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 500;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  height: 30px;
  margin-top: 24px;
  margin-right: 16px;
  @media (max-width: 650px) {
    margin-top: 0px;
  }
`;

const DivTemporaryBlock = styled.div`
  font-size: 16px;
  color: #333333;
`;

const BlockedScreen = () => {
  const { underageUser, setStep } = useContext(ParentalConsentContext);
  const { user } = useAuth();
  let blockedAt = '';
  if (underageUser.blockedAt) {
    blockedAt = moment(underageUser.blockedAt).format('DD/MM/YYYY');
  }

  let blockedUntil = '';
  if (underageUser.blocked_until) {
    blockedUntil = moment(underageUser.blocked_until).format('DD/MM/YYYY');
  }

  function confirmUnblock() {
    window.dataLayer.push({
      event: 'customEventUserInteraction',
      event_category: Categories.ParentalConsent,
      event_action: Actions.ClickUnblockAccount,
      event_label: underageUser.underageId,
      bd_suser_code: user?.globoId,
    });
    setStep({ screen: 'confirm', type: 'unblock' });
  }

  return (
    <>
      <div>
        <h2>Bloqueio temporário</h2>
      </div>
      <DivTemporaryBlock>
        Você bloqueou a Conta Globo de {underageUser.name} em {blockedAt}
      </DivTemporaryBlock>
      <DivDetails>
        <DivBlock>
          <p>Conta Bloqueada</p>
        </DivBlock>
        {blockedUntil && (
          <DivWhen>Desbloqueio programado para {blockedUntil}</DivWhen>
        )}
      </DivDetails>
      <DivFooter>
        <Button
          width="264px"
          onClick={() => confirmUnblock()}
          variant="secondary"
        >
          Desbloquear Conta Globo
        </Button>
      </DivFooter>
    </>
  );
};

export default BlockedScreen;
