import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Actions, Categories, Labels } from '../../types/gtm';
import Container from '../../components/Container';
import Header from '../../components/Header';
import Button from '../../components/Button';
import { useAuth } from '../../hooks/useAuth';
import Checkmark from '../../static/img/feedback-success.svg';
import Icon from '../../components/Section/Icon';
import { Content, StyleTitle } from './styles';

type LocationState = {
  nameAccount: string;
  activeAccount: boolean;
};

const ManagerAccounts = () => {
  const { user, logout } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const state = location.state as LocationState;
  const nameAccount = state?.nameAccount;
  const activeAccount = state?.activeAccount;

  const label = activeAccount
    ? Labels.ConnectedPartnerScreen
    : Labels.DisconnectedPartnerScreen;

  const labelInverted = !activeAccount
    ? Labels.ConnectedPartnerScreen
    : Labels.DisconnectedPartnerScreen;

  return (
    <>
      <>
        <Header
          user={user}
          onLogout={logout}
          showReturn
          screenMenu={Categories.AssociateAccounts}
          labelMenu={label + nameAccount}
          onReturn={() => {
            window.dataLayer.push({
              event: 'customEventUserInteraction',
              event_category: Categories.AssociateAccounts,
              event_action: Actions.ClickBack,
              event_label: label + nameAccount,
              bd_suser_code: user?.globoId,
            });

            history.goBack();
          }}
        />
        <Container>
          <Content>
            <StyleTitle>
              <Icon svg={Checkmark} />
              {activeAccount ? (
                <h1>Conexão removida</h1>
              ) : (
                <h1>Conexão adicionada</h1>
              )}
            </StyleTitle>
            {activeAccount ? (
              <p>
                Você removeu todas as conexões com <b>{nameAccount}</b> criadas
                para fazer login.
              </p>
            ) : (
              <p>
                Agora você poderá entrar em <b>{nameAccount}</b> utilizando sua
                Conta Globo.
              </p>
            )}

            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                window.dataLayer.push({
                  event: 'customEventUserInteraction',
                  event_category: Categories.AssociateAccounts,
                  event_action: Actions.ClickConclude,
                  event_label: labelInverted + nameAccount,
                  bd_suser_code: user?.globoId,
                });

                history.goBack();
              }}
            >
              Concluir
            </Button>
          </Content>
        </Container>
      </>
    </>
  );
};

export default ManagerAccounts;
