import { createContext, useState } from 'react';
import {
  ButtonIntervention,
  Content,
  IconAlert,
  PopUpMessageIntervention,
  TitleMessageIntervention,
  DescriptionMessageIntervention,
  PopUpContainer,
} from './styles';
import alertIcon from '../../static/img/alert-icon-banner.svg';
import alertIconBanner from '../../static/img/alert-red.svg';
import ModalConfirm from '../../pages/ModalConfirm';
import { Actions, Categories, GetInterventionCategory } from '../../types/gtm';
import { User } from '../../types/user';

type ModalContextType = {
  context: boolean | null;
  setContext: React.Dispatch<React.SetStateAction<boolean | null>>;
};

const iModalContextState = {
  context: false,
  setContext: () => {},
};

export type MessageIntervention = {
  type: string;
  title: string;
  description: string;
  dialogTitle: string;
  dialogDescription: string;
};

export const ModalContext = createContext<ModalContextType>(iModalContextState);

export interface Props {
  messageIntervention: MessageIntervention;
  user?: User;
  isBanner?: boolean;
  hasInconsistency?: boolean;
}

export default function PopUpIntervention({
  messageIntervention,
  user,
  isBanner,
  hasInconsistency,
}: Props) {
  const [context, setContext] = useState<boolean | null>(false);
  const [dismiss, setDismiss] = useState<boolean>(false);

  const userData = { ...user };

  const formatPhones = () => {
    return {
      ...userData.phones,
      cellPhone: `${userData.phones?.dddCellPhone}${userData.phones?.cellPhone}`,
    };
  };

  const getName = () => {
    if (userData.fullName) {
      const name = userData.fullName.split(' ');
      return name[0];
    }
    return userData.fullName;
  };

  return (
    <div>
      {context !== false && (
        <ModalContext.Provider value={{ context, setContext }}>
          <ModalConfirm
            userData={{
              ...user,
              phones: formatPhones(),
            }}
            messageFields={messageIntervention}
            onConfirm={() => {
              setDismiss(true);
            }}
          />
        </ModalContext.Provider>
      )}
      {!dismiss && (
        <Content hasInconsistency={hasInconsistency || false}>
          <PopUpContainer isBanner={isBanner || false}>
            {hasInconsistency ? (
              <IconAlert src={alertIconBanner} alt="Icone-de-alerta" />
            ) : (
              <IconAlert src={alertIcon} alt="Icone-de-alerta" />
            )}
            <PopUpMessageIntervention>
              {hasInconsistency ? (
                <TitleMessageIntervention hasInconsistency>
                  {getName() + messageIntervention.title}
                </TitleMessageIntervention>
              ) : (
                <TitleMessageIntervention hasInconsistency={false}>
                  {messageIntervention.title}
                </TitleMessageIntervention>
              )}
              <DescriptionMessageIntervention isBanner={isBanner ? 500 : 420}>
                {messageIntervention.description}
              </DescriptionMessageIntervention>
            </PopUpMessageIntervention>
            {!isBanner && (
              <ButtonIntervention
                onClick={() => {
                  window.dataLayer.push({
                    event: 'customEventUserInteraction',
                    event_category: Categories.EditUserPersonalData,
                    event_action: Actions.ClickRegister,
                    event_label: GetInterventionCategory(
                      user,
                      messageIntervention,
                    ),
                    bd_suser_code: user?.globoId,
                  });
                  setContext(true);
                }}
              >
                Continuar
              </ButtonIntervention>
            )}
          </PopUpContainer>
        </Content>
      )}
    </div>
  );
}
