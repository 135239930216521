import { UserData } from '../types/userData';
import { Categories } from '../types/gtm';

const actionGTMMap: Record<string, string> = {
  fullName: 'Nome Alterado',
  birthDate: 'Data de Nascimento Alterado',
  docNumber: 'CPF Alterado',
  gender: 'Genero Alterado',
  phone: 'Telefone Residencial Alterado',
  cellPhone: 'Telefone Celular Alterado',
  zipcode: 'CEP Alterado',
  address1: 'Logradouro Alterado',
  number: 'Numero Alterado',
  address2: 'Complemento Alterado',
  neighborhood: 'Bairro Alterado',
  country: 'Pais Alterado',
  state: 'Estado Alterado',
  city: 'Cidade Alterado',
};

const labelGTMMap: Record<string, string> = {
  address: 'Endereco',
  phones: 'Telefone',
  registration: 'Pessoal',
};

function getLabelGTM(value: string, initialValue: any, field: string) {
  const complementLabel =
    initialValue && JSON.stringify(initialValue) !== '{}'
      ? 'Complemento'
      : 'Incompleto para Completo';

  const label = !['address', 'phones'].includes(field) ? 'registration' : field;

  return `${labelGTMMap[label]} - ${
    value ? `${complementLabel}` : 'Completo para Incompleto'
  }`;
}

function sendEventToGTM(action: string, label: string, globoId: string) {
  return window.dataLayer.push({
    event: 'customEventUserInteraction',
    event_category: Categories.EditUserPersonalData,
    event_action: action,
    event_label: label,
    bd_suser_code: globoId,
  });
}

function checkSomeId(obj1: any, obj2: any) {
  return obj1.id === obj2.id;
}

function sendGTMEventForChangedFields(
  values: any,
  initialValues: any,
  globoId: string,
  field: string,
) {
  const keysInitialValues = Object.keys(initialValues);
  keysInitialValues.forEach(key => {
    if (initialValues[key] !== values[key]) {
      if (typeof initialValues[key] === 'object') {
        // Recursively call this function for nested objects
        sendGTMEventForChangedFields(
          values[key],
          initialValues[key],
          globoId,
          key,
        );
      }
      if (actionGTMMap[key]) {
        let action = actionGTMMap[key];
        let label = getLabelGTM(values[key], initialValues[key], field);

        if (['country', 'state', 'city'].includes(key)) {
          if (!checkSomeId(initialValues[key], values[key])) {
            action = actionGTMMap[key];
            label = getLabelGTM(values[key], initialValues[key], field);
            sendEventToGTM(action, label, globoId);
          }
        } else if (['phone', 'cellPhone'].includes(key)) {
          if (initialValues[key].substring(2, 16) !== values[key]) {
            action = actionGTMMap[key];
            label = getLabelGTM(values[key], initialValues[key], field);
            sendEventToGTM(action, label, globoId);
          }
        } else {
          sendEventToGTM(action, label, globoId);
        }
      }
    }
  });
}

export default function sendFieldsChangedToGTM(
  values: UserData,
  initialValues: UserData,
  globoId: string,
) {
  sendGTMEventForChangedFields(values, initialValues, globoId, '');
}
