import Content from '../../../../components/Section/Content';
import Box from '../../../../components/Section/Box';
import Icon from '../../../../components/Section/Icon';
import svg from '../../../../static/img/icon-open-menu.svg';
import { Categories, Actions, Labels } from '../../../../types/gtm';
import { useAuth } from '../../../../hooks/useAuth';
import Action from '../../../../components/Section/Action';
import environmentConfig from '../../../../config/environment-configs';

const BoxFamily = () => {
  const { user } = useAuth();
  const title = 'Minha Família';
  const describeTitle = 'Gerencie ou adicione familiares à sua família';
  return (
    <Box title={title} describeTitle={describeTitle}>
      <Content>
        <Action
          onClick={() => {
            window.dataLayer.push({
              event: 'customEventUserInteraction',
              event_category: Categories.Home,
              event_action: Actions.ClickManageFamily,
              event_label: Labels.Home,
              bd_suser_code: user?.globoId,
            });
          }}
          href={environmentConfig.MY_FAMILY_URL}
          theme="link"
        >
          <p>Gerenciar família</p>
          <Icon svg={svg} alt="icon-open-menu" />
        </Action>
      </Content>
    </Box>
  );
};

export default BoxFamily;
